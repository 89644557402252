import React from "react";

import type { HeaderProps } from "../../../../../types";

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <div
      style={{
        color: "#ffffff",
        height: "60px",
        backgroundColor: "#389ebb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: "22px" }}>
        {props.title} ({props.number})
      </div>
    </div>
  );
};

export default Header;
