import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Publisher: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1899 7.27993L14.1899 1.67993"
          stroke="#0D3AF8"
          strokeWidth="0.82"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7501 2.18003H5.89006C5.68829 2.17337 5.4872 2.20669 5.29838 2.2781C5.10955 2.34951 4.93672 2.45758 4.78985 2.59608C4.64298 2.73458 4.52497 2.90079 4.44263 3.08511C4.36028 3.26943 4.31523 3.46821 4.31006 3.67002V20.47C4.32313 20.8767 4.49664 21.2618 4.7927 21.5409C5.08875 21.8201 5.48327 21.9708 5.89006 21.96H18.1201C18.3256 21.9722 18.5315 21.9429 18.7255 21.8741C18.9196 21.8053 19.0979 21.6983 19.2498 21.5594C19.4018 21.4205 19.5244 21.2525 19.6103 21.0654C19.6962 20.8783 19.7437 20.6758 19.7501 20.47V7.76004"
          stroke="#0D3AF8"
          strokeWidth="2.11"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          d="M20.04 7.65007H15.52C15.1699 7.65007 14.8341 7.51099 14.5866 7.26344C14.339 7.01589 14.2 6.68015 14.2 6.33006V1.81006"
          fill="#0D3AF8"
        />
        <path
          d="M8.71997 10.99H15.27"
          stroke="#0D3AF8"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.72998 14.27H15.22"
          stroke="#0D3AF8"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.71997 17.51H11.96"
          stroke="#0D3AF8"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <clipPath id="clip0_180_4325">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Blank>
  );
};
export default Publisher;
