import React from "react";

import { Themes } from "../TemplateGallery";
import { ThemeList } from "../types";

import type { BaseTemplateProps } from "../types";

const ObjectivesBaseTemplate: React.FC<BaseTemplateProps> = (props) => {
  return (
    <>
      {(() => {
        switch (props.theme) {
          case ThemeList.Classic:
            return (
              <Themes.Classic.Objectives
                objectives={props.PageData.objectives}
                assignmentPages={props.PageData.assignmentPages}
              />
            );
          case ThemeList.Modern:
            return (
              <Themes.Modern.Objectives
                objectives={props.PageData.objectives}
                assignmentPages={props.PageData.assignmentPages}
              />
            );
          default:
            return;
        }
      })()}
    </>
  );
};

export default ObjectivesBaseTemplate;
