import { combineReducers } from "redux";
import { StatisticsReducer } from "./statistics/reducers/StatisticsReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { BlackboardReducer } from "./integrations/reducers/BlackboardReducers";
import { WebexReducer } from "./integrations/reducers/WebexReducers";
import { MicrosoftTeamsReducer } from "./integrations/reducers/MicrosoftTeamsReducers";
import { CommonReducer as IntegrationsCommonReducer } from "./integrations/reducers/CommonReducers";
import { CanvasReducer } from "./integrations/reducers/CanvasReducers";
import { CommonReducer } from "./common/reducers/CommonReducer";
import { OnboardingReducer } from "./onboarding/reducers/OnboardingReducers";
import { SavedPasswordsReducer } from "./students/reducers/SavedPasswordsReducers";
import { SyllabusReducer } from "./syllabus/reducers/SyllabusReducers";
import { OneWeekPlanReducer } from "./one-week-plan/reducers/OneWeekPlanReducers";
import { SyllabusTemplateReducer } from "./syllabus_templates/reducers/SyllabusTemplateReducers";
import { TeacherModeReducer } from "redux/teacher-mode/reducers/TeacherModeReducer";
import { CourseMapReducer } from "redux/integrations/reducers/CourseMapReducers";

export default combineReducers({
  statistics: StatisticsReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  integrations: combineReducers({
    common: IntegrationsCommonReducer,
    blackboard: BlackboardReducer,
    webex: WebexReducer,
    microsoftTeams: MicrosoftTeamsReducer,
    canvas: CanvasReducer,
    courseMap: CourseMapReducer,
  }),
  common: CommonReducer,
  onboarding: OnboardingReducer,
  students: combineReducers({
    savedPasswords: SavedPasswordsReducer,
  }),
  syllabus: SyllabusReducer,
  oneWeekPlan: OneWeekPlanReducer,
  syllabusTemplate: SyllabusTemplateReducer,
  teacherMode: TeacherModeReducer,
});
