import React from "react";

import type { InstructorsProps } from "../../../../../types";

const Instructors: React.FC<InstructorsProps> = (props) => {
  return (
    <>
      {props.instructors.length > 1 ? (
        <h1>Instructors</h1>
      ) : (
        <h1>Instructor</h1>
      )}
      {props.instructors.map((instructor, index) => {
        return (
          <ul key={index}>
            <li>Facilitator: {instructor.instructor}</li>
            <li>
              Email: <a href={`mailto:${instructor.mail}`}>{instructor.mail}</a>
            </li>
          </ul>
        );
      })}
    </>
  );
};

export default Instructors;
