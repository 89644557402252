import React from "react";
import { Grid } from "grommet";
import TextInput from "../../ui-kit/TextInput/TextInput";
import styled from "styled-components";
import { Activity } from "../../../model/Resource";
import { formatInTimeZone } from "../../../helpers/formatInTimeZone";
import ActivitiesEditorViewer from "../../ui-kit/RichTextEditor/Viewer/ActivitiesEditorViewer";

interface ActivityEditorProps {
  activity: Activity;
  startOfWeek?: Date;
  onChange?: (activity: Activity) => void;
  onClickSearchForMaterials?: (activity: Activity, index: number) => void;
  onDeleteActivity?: (activity: Activity) => void;
  index: number;
  className?: string;
  needsRegistration?: boolean;
  errors?: Record<string, string> | undefined;
}

const ActivityPill = styled.span`
  padding: 0 23px;
  background: #0d3af8;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
`;

const ActivityEditor: React.FC<ActivityEditorProps> = (props) => {
  return (
    <div
      className={props.className}
      key={`activity-editor-index-${props.index}`}
    >
      <ActivityPill>Assignment #{props.index + 1}</ActivityPill>
      <Grid columns={["420px", "1/3"]} gap="small">
        <TextInput
          disabled={true}
          label="Assignment Name"
          required={true}
          value={props.activity.title}
          placeholder={"Assignment Name"}
          error={props.errors?.[`activities[${props.index}].title`]}
        />
        <TextInput
          disabled={true}
          label="Due Date"
          a11yTitle="Select Due Date"
          value={formatInTimeZone(props.activity.dueDate, "yyyy/MM/dd", "UTC")}
        />
      </Grid>
      <ActivitiesEditorViewer
        id={`activity-index-${props.index}`}
        key={props.activity.id}
        value={props.activity.body}
      />
    </div>
  );
};

export default ActivityEditor;
