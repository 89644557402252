import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Tracker: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.99 2H4.01C2.89991 2 2 2.89991 2 4.01V19.99C2 21.1001 2.89991 22 4.01 22H19.99C21.1001 22 22 21.1001 22 19.99V4.01C22 2.89991 21.1001 2 19.99 2Z"
          stroke="#0D3AF8"
          strokeWidth="2.05"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.96997 4H5.96997C5.41769 4 4.96997 4.44772 4.96997 5V14.39C4.96997 14.9423 5.41769 15.39 5.96997 15.39H6.96997C7.52226 15.39 7.96997 14.9423 7.96997 14.39V5C7.96997 4.44772 7.52226 4 6.96997 4Z"
          fill="#0D3AF8"
        />
        <path
          d="M12.5 4H11.5C10.9477 4 10.5 4.44772 10.5 5V10C10.5 10.5523 10.9477 11 11.5 11H12.5C13.0523 11 13.5 10.5523 13.5 10V5C13.5 4.44772 13.0523 4 12.5 4Z"
          fill="#0D3AF8"
        />
        <path
          d="M17.97 4H16.97C16.4177 4 15.97 4.44772 15.97 5V17C15.97 17.5523 16.4177 18 16.97 18H17.97C18.5223 18 18.97 17.5523 18.97 17V5C18.97 4.44772 18.5223 4 17.97 4Z"
          fill="#0D3AF8"
        />
        <defs>
          <clipPath id="clip0_180_4324">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Blank>
  );
};
export default Tracker;
