import React from "react";

import Header from "./Sections/Header";
import Instructors from "./Sections/Instructors";
import Description from "./Sections/Description";
import Objectives from "./Sections/Objectives";
import Schedule from "./Sections/Schedule";
import OfficeHours from "./Sections/OfficeHours";

import type { CoursePageProps } from "../../../../types";
import { appConfig } from "config/config";

const CoursePage: React.FC<CoursePageProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      {props.header.title && props.header.number && (
        <Header title={props.header.title} number={props.header.number} />
      )}
      {props.instructors?.instructors && (
        <Instructors instructors={props.instructors.instructors} />
      )}
      {props.officeHours?.officeHours && (
        <OfficeHours officeHours={props.officeHours.officeHours} />
      )}
      {props.description.description && (
        <Description description={props.description.description} />
      )}
      {props.objectives.objectives && (
        <Objectives objectives={props.objectives.objectives} />
      )}
      {props.schedule.weeks && <Schedule weeks={props.schedule.weeks} />}
      <p>
        <a href={`${appConfig.completeUrl}c/${props.firebaseId}`}>
          Complete BlendEd syllabus
        </a>
      </p>
    </div>
  );
};

export default CoursePage;
