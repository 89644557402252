import React, { useEffect } from "react";
import { Box } from "grommet";
import styled from "styled-components";
import CourseMapAPI from "helpers/CourseMap/CourseMapAPI";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import LoadingAnimation from "./LoadingAnimation";
import { useQuery } from "hooks/useQuery";

const Container = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
`;
const Login: React.FC = () => {
  const firebase = useFirebase();
  const auth = firebase.auth();
  const history = useHistory();
  const query = useQuery();
  const courseName = encodeURI(query.get("name")!);
  const courseNumber = encodeURI(query.get("num")!);
  const courseId = encodeURI(query.get("id")!);

  useEffect(() => {
    window.analytics.track("Started Login");
    let timer: NodeJS.Timeout;
    if (auth.currentUser) {
      return;
    } else {
      timer = setTimeout(() => {
        history.replace("/logout");
      }, 1000);
    }

    const courseMapAPI = new CourseMapAPI();
    courseMapAPI.getAuthURL().then((url) => {
      clearTimeout(timer);
      window.analytics.track("Get Planner auth url");
      // @ts-ignore
      window.location = `${url}?name=${courseName}&num=${courseNumber}&id=${courseId}`;
    });
  });

  return (
    <Container>
      <LoadingAnimation />
    </Container>
  );
};

export default Login;
