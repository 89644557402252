import { SyllabusActions, SyllabusTypes } from "../types/SyllabusTypes";
import { OpenedResource } from "../../../model/Resource";

export interface SyllabusState {
  openedResources: OpenedResource[];
  plannerId: string;
}

const initialState: SyllabusState = {
  openedResources: [],
  plannerId: "",
};

export const SyllabusReducer = (
  state = initialState,
  action: SyllabusActions
): SyllabusState => {
  switch (action.type) {
    case SyllabusTypes.AddOpenedResource:
      return {
        ...state,
        openedResources: action.payload.openedResources,
      };
    case SyllabusTypes.ClearOpenedResources:
      return {
        ...state,
        openedResources: initialState.openedResources,
      };
    case SyllabusTypes.SetPlannerId:
      return {
        ...state,
        plannerId: action.payload.plannerId,
      };
    default:
      return state;
  }
};
