import React from "react";
import { Objective } from "../../../model/Syllabus";
import styled from "styled-components";

interface ObjectivesEditorProps {
  objective: Objective;
}

const StyledLabel = styled.span`
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #474e61;
  margin: 8px 0;

  & .required {
    color: #e51144;
    margin-left: 5px;
  }
`;

const StyledSubLabel = styled.span`
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #474e61;
  margin: 8px 0;

  & .required {
    color: #e51144;
    margin-left: 5px;
  }
`;

const ObjectivesEditor: React.FC<ObjectivesEditorProps> = ({ objective }) => {
  return (
    <>
      <StyledLabel>{objective.title}</StyledLabel>
      <StyledSubLabel>{objective.description}</StyledSubLabel>
    </>
  );
};

export default ObjectivesEditor;
