import React from "react";

import type { DescriptionProps } from "../../../../../types";

const Description: React.FC<DescriptionProps> = (props) => {
  return (
    <div style={{ margin: "16px 0px" }}>
      <h1
        style={{
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "25px",
          color: "#030b1d",
        }}
      >
        Description
      </h1>
      <div
        style={{
          marginTop: "16px",
          fontWeight: "400",
          fontSize: "12pt",
          lineHeight: "19px",
          color: "#474e61",
        }}
      >
        {props.description}
      </div>
    </div>
  );
};

export default Description;
