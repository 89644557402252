export enum CourseMapActionType {
  SaveLoginCredentials = "SaveLoginCredentials",
}

export interface saveLoginCredentials {
  type: typeof CourseMapActionType.SaveLoginCredentials;
  payload: LoginCredentials;
}

export type CourseMapActions = saveLoginCredentials;

export interface LoginCredentials {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  uid: string;
}
