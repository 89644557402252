import React, { useState } from "react";
import { CourseTitle, Schedule, Syllabus } from "../../model/Syllabus";
import SectionComponent from "../sections/view/SectionComponent";
import styled from "styled-components";
import { Box, Button, Collapsible } from "grommet";
import { Close, Menu } from "grommet-icons";
import Toc from "./Toc";
import { useHistory, useLocation } from "react-router-dom";
import SyllabusViewerContextProvider from "./SyllabusViewerContextProvider";
import SyllabusThemeContextProvider from "./customization/SyllabusTheme";
import RichTextEditorContextProvider from "../ui-kit/RichTextEditor/RichTextEditorContext";

const Root = styled.div`
  min-height: 100vh;
  background: #fbfdff;
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Content = styled(Box)`
  background: #ffffff;
  border: 1px solid #f1f6ff;
  box-sizing: border-box;
  box-shadow: 0 0 8px 1px rgba(7, 26, 67, 0.05);
  border-radius: 6px;
  margin: 0px 35px;
  height: 100%;
  padding: 75px;

  & a {
    color: #2256f2;
  }
`;

export interface NormalMode {
  type: "normal";
  onClickLinkSyllabusToStudent?: () => void;
}

export interface OneWeekPlanMode {
  type: "one-week-plan";
  onClickContinueEditing: () => void;
}

export type Mode = NormalMode | OneWeekPlanMode;

interface SyllabusViewerProps {
  /**
   * the syllabus to view
   */
  syllabus: Syllabus;

  mode?: Mode;

  publicMode?: boolean;

  clickOnPrivateContent?: () => void;
}

/**
 * The publicly available syllabus viewer for students/users without edit access to a syllabus.
 */
const SyllabusViewer: React.FC<SyllabusViewerProps> = ({
  syllabus,
  mode,
  publicMode,
  clickOnPrivateContent,
}) => {
  const courseTitle = syllabus.sections.find(
    (section) => section.type === "CourseTitle"
  ) as CourseTitle;

  const history = useHistory();
  const location = useLocation().pathname;
  const [showSidebar, setShowSidebar] = useState(false);

  const renderedSyllabus = (
    <>
      <StyledButton
        icon={showSidebar ? <Close color="brand" /> : <Menu color="brand" />}
        onClick={() => setShowSidebar(!showSidebar)}
      />
      <Content role="Syllabus Container">
        <RichTextEditorContextProvider
          mode="student-view"
          unlocked={publicMode || syllabus.password === ""}
          clickOnPrivateContent={() => clickOnPrivateContent?.()}
        >
          {syllabus.sections.map((section, index) => (
            <Box background="white" pad={{ vertical: "xsmall" }} key={index}>
              <SectionComponent
                id={"section-" + index}
                key={"section-" + index}
                section={section}
                onClickActivity={(activity) => {
                  if (activity) {
                    if (section.type === "Schedule") {
                      const schedule = section as Schedule;
                      const mIndex =
                        schedule.modules.findIndex((module) =>
                          module.activities.some((a) => a.id === activity.id)
                        ) || 0;
                      const aIndex =
                        schedule.modules[mIndex].activities.indexOf(activity) ||
                        0;
                      history.push(
                        `${location}/module/${mIndex}#activity-${aIndex}`
                      );
                    }
                  }
                }}
                onClickWeek={(module) => {
                  if (section.type === "Schedule") {
                    const schedule = section as Schedule;
                    const mIndex = schedule.modules.indexOf(module) || 0;
                    history.push(`${location}/module/${mIndex}`);
                  }
                }}
              />
            </Box>
          ))}
        </RichTextEditorContextProvider>
      </Content>
    </>
  );

  return (
    <SyllabusViewerContextProvider publicMode={publicMode}>
      <SyllabusThemeContextProvider
        fontFamily={syllabus.customization.fontFamily}
        primaryColor={syllabus.customization.primaryColor}
      >
        <RichTextEditorContextProvider mode="student-view">
          <Root role={"Syllabus Viewer"}>
            <Collapsible direction="horizontal" open={showSidebar}>
              <Toc syllabus={syllabus} onClick={() => {}} />
            </Collapsible>
            <Box
              margin={showSidebar ? { left: "19em" } : {}}
              a11yTitle={`Syllabus Viewer ${courseTitle.title}, move to your left to navigate from the sidebar`}
            >
              {renderedSyllabus}
            </Box>
          </Root>
        </RichTextEditorContextProvider>
      </SyllabusThemeContextProvider>
    </SyllabusViewerContextProvider>
  );
};

export default SyllabusViewer;
