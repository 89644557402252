/*******************************
 *     PRODUCTION Config
 ******************************/

export const canvasConfig = {
  redirectUrl: "https://app.blendedcourse.com/account/integrations",
  redirectToSyllabus: "https://app.blendedcourse.com/loaderRedirect",
};

export const teamsConfig = {
  redirectUrl: "https://app.blendedcourse.com/account/integrations",
  redirectToSyllabus: "https://app.blendedcourse.com/loaderRedirect",
  clientId: "2feba7b6-d6a6-425f-96c9-f4ced855ff53",
};

export const teamsConfigPrivate = {
  ...teamsConfig,
  ...{
    clientSecret: "hOR7Q~-pOgDI_2UYWfA_.INwDfh_DASTpGe-y",
  },
};

export const blackboardConfig = {
  redirectToSyllabus: "https://app.blendedcourse.com/loaderRedirect",
};

export const stripeConfig = {};

export const stripeConfigPrivate = { ...stripeConfig, ...{} };

export const rrfConfig = {};

export const webexConfig = {
  redirectUrl: "https://app.blendedcourse.com/account/integrations",
};

export const youtubeConfig = {
  key: "AIzaSyAWPsL-fSTij9N8PipRS6-weXU-V4Njo20",
};

export const googleBooksConfig = {
  key: "AIzaSyDwIkFAwsrpTWmHRZStIXGjEYMJ3F8tp6E",
};

export const customSearchConfig = {
  key: "AIzaSyA04yfPjcMsxauD-UgEGJ6DdTdPC32rgiQ",
};

export const webexConfigPrivate = { ...webexConfig, ...{} };

export const firebaseConfig = {
  apiKey: "AIzaSyAMlJL_8EVkp346HsrXWJdijovl_cUgj3s",
  authDomain: "blended-production.firebaseapp.com",
  projectId: "blended-production",
  storageBucket: "blended-production.appspot.com",
  messagingSenderId: "82941687974",
  appId: "1:82941687974:web:ff22d939da3ea73e6a1c67",
  measurementId: "G-C0KNMZQWH5",
  serviceAccountId:
    "firebase-adminsdk-8e2nb@blended-production.iam.gserviceaccount.com",
};

export const appConfig = {
  authorizeDocUrl: "https://app.blendedcourse.com/content/",
  authorisationDone: "https://app.blendedcourse.com/contentAuthorizationDone",
  canvasLtiUrl: "https://app.blendedcourse.com/lti",
  presentDocUrl: "https://app.blendedcourse.com/doc/",
  completeUrl: "https://app.blendedcourse.com/",
};

export const reflectConfig = {
  avoidRecaptchaUsingReflect: false,
};

export const courseMapConfig = {
  clientId: "54fdc8565f65316ad0ffef8da04899fe",
  clientSecret: "4e6c720b5876886dee002b933d293413",
  redirectUri: "https://app.blendedcourse.com/plan/authorize",
  baseUrl: "https://plan.blendedcourse.com/api/1.1/obj",
  authUrl: "https://plan.blendedcourse.com/api/1.1",
  courseUrl: "https://plan.blendedcourse.com/course-editor?courseId=",
  projectUrl: "https://plan.blendedcourse.com/project_board?courseId=",
  accessToken: "4356d74f3a5b0324db74a9f075af0e26",
  dashUrl: "https://plan.blendedcourse.com/course-dash?courseId=",
  homeUrl: "https://plan.blendedcourse.com/",
};

export const ltiConfig = {
  url: "https://app.blendedcourse.com/lti",
};

export const functionsConfig = {
  url: "https://us-central1-blended-production.cloudfunctions.net/",
};
