import React from "react";

import type { ActivityPageProps } from "../../../../types";

const ActivitiesPage: React.FC<ActivityPageProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      <h3>{props.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: props.body }} />
      <div
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #d0dcf1",
          borderRadius: "6px",
          padding: "20px",
          marginBottom: "10px",
        }}
      >
        Learning Objectives
        <ul style={{ marginTop: "12px" }}>
          {props.objectives.map((objective) => {
            return (
              <li
                key={objective.id}
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#030b1d",
                }}
              >
                {objective.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ActivitiesPage;
