import React from "react";
import ReactDOMServer from "react-dom/server";

import CourseBaseTemplate from "../components/canvasTemplates/BaseTemplates/CourseBaseTemplate";
import ActivitiesBaseTemplate from "../components/canvasTemplates/BaseTemplates/ActivitiesBaseTemplate";
import ObjectivesBaseTemplate from "../components/canvasTemplates/BaseTemplates/ObjectivesBaseTemplate";

import type { ThemeType } from "../components/canvasTemplates/types";
import type { Syllabus } from "../model/Syllabus";
import { SyllabusToThemeInterface } from "./syllabusToThemeInterface";

export const generateHTML = (
  theme: ThemeType,
  syllabus: Syllabus
): (string | Array<string>[] | Array<string>)[] => {
  const { Course, Activities, Objectives } = SyllabusToThemeInterface(syllabus);

  let ExportableCourse: string = "";
  let ExportableActivities: Array<string>[] = [[]];
  let ExportableObjectives: Array<string> = [];

  try {
    ExportableCourse = ReactDOMServer.renderToStaticMarkup(
      <CourseBaseTemplate theme={theme} PageData={Course} />
    ).toString();
    ExportableActivities = Activities.map((Group) => {
      return Group.map((Activity) => {
        return ReactDOMServer.renderToStaticMarkup(
          <ActivitiesBaseTemplate theme={theme} PageData={Activity} />
        ).toString();
      });
    });
    ExportableObjectives = Objectives.map((Objective) => {
      return ReactDOMServer.renderToStaticMarkup(
        <ObjectivesBaseTemplate theme={theme} PageData={Objective} />
      ).toString();
    });
  } catch (error) {
    console.error(error);
  }

  return [ExportableCourse, ExportableActivities, ExportableObjectives];
};
