import { Objective } from "../../model/Syllabus";

export type ThemeType = "Classic" | "Modern";

export enum ThemeList {
  Classic = "Classic",
  Modern = "Modern",
}

export interface BaseTemplateProps {
  theme: ThemeType;
  PageData: any;
}

export interface CoursePageProps {
  header: HeaderProps;
  instructors?: InstructorsProps;
  officeHours?: OfficeHoursProps;
  description: DescriptionProps;
  objectives: ObjectivesProps;
  schedule: ScheduleProps;
  firebaseId: string;
}

export interface HeaderProps {
  title: string;
  number: string;
}

export interface InstructorsProps {
  instructors: Array<{
    instructor: string;
    mail: string;
  }>;
}

export interface OfficeHoursProps {
  officeHours: string;
}

export interface DescriptionProps {
  description: string | null;
}

export interface ObjectivesProps {
  objectives: Array<{
    id: string;
    title: string;
    description: string;
  }> | null;
}

export interface ScheduleProps {
  weeks: Array<{
    title: string;
  }>;
}

export interface ButtonProps {
  firebaseId: string;
}

export interface ObjectivesPageProps {
  objectives: Array<{
    id: string;
    title: string;
    description: string;
  }>;
  assignmentPages: Array<{ title: string; body: string; index: string }>;
}

export interface ActivityPageProps {
  body: string;
  title: string;
  objectives: Objective[];
}
