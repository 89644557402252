import React from "react";
// import Header from "./Sections/Header";
import Description from "./Sections/Description";
import Objectives from "./Sections/Objectives";
import Schedule from "./Sections/Schedule";
import Button from "./Sections/Button";

import type { CoursePageProps } from "../../../../types";
import Instructors from "./Sections/Instructors";

const CoursePage: React.FC<CoursePageProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      {/* {{props.header.title && props.header.number && (
        <Header title={props.header.title} number={props.header.number} />
      )}} */}

      {props.instructors &&
        props.instructors.instructors &&
        props.instructors.instructors.length > 0 && (
          <Instructors instructors={props.instructors.instructors} />
        )}
      {props.description.description && (
        <Description description={props.description.description} />
      )}
      {props.objectives.objectives && (
        <Objectives objectives={props.objectives.objectives} />
      )}
      {props.schedule.weeks && <Schedule weeks={props.schedule.weeks} />}
      <Button firebaseId={props.firebaseId} />
    </div>
  );
};

export default CoursePage;
