import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/configureStore";
import { canvasConfig } from "config/config";
import firebase from "firebase";
import { Schedule, Syllabus } from "model/Syllabus";
import { HidableComponent } from "components/hoc/Hidable";
import { setPreSelectedCourse } from "redux/integrations/actions/CanvasActions";
import SyllabusRepository from "../../repositories/SyllabusRepository";

import { generateHTML } from "../../helpers/generateHTMLFromCanvasTemplate";
import PublicSyllabusRepository from "../../repositories/PublicSyllabusRepository";
import { useParams } from "react-router-dom";
import CanvasExportModalDialog from "./CanvasExportDialog";

interface SyllabusCanvasExportProps {
  syllabus: Syllabus;
  onClickCancel?: () => void;
  onClose: () => void;
  onExport: (canvasId: string) => void;
  opened: boolean;
}

const CanvasExportModal: React.FC<SyllabusCanvasExportProps> = (props) => {
  const [canvasId, setCanvasId] = useState<string | undefined>(undefined);
  const { syllabus } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const canvas = useAppSelector((state) => state.integrations.canvas);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();
  const syllabusRepository = new SyllabusRepository();
  const canvasScopesRedux = canvas.scopes?.length;
  const canvasScopesConfig = canvasConfig.scopes.length;

  useEffect(() => {
    const handleCanvasLogin = async () => {
      const instanceToken = canvas.refreshToken;
      let courseId = props.syllabus.firebaseId;
      const instanceData = {
        url: canvasConfig.canvasURL,
        clientId: canvasConfig.clientId,
        clientSecret: canvasConfig.clientSecret,
        scope: canvasConfig.scopes.join(" "),
      };

      if (!instanceToken || canvasScopesConfig !== canvasScopesRedux) {
        const url = `${instanceData.url}/login/oauth2/auth?client_id=${instanceData.clientId}&response_type=code&state=canvassyllabusId=${courseId}&redirect_uri=${canvasConfig.redirectToSyllabus}&scope=${instanceData.scope}`;
        // @ts-ignore
        window.location = url;
      } else {
        setIsOpenDialog(true);
      }
    };

    handleCanvasLogin();
    setIsOpenDialog(true);
  }, [
    canvas.refreshToken,
    props.syllabus,
    canvasScopesConfig,
    canvasScopesRedux,
  ]);

  const onClickExportToCanvas = async (courseId: string | undefined) => {
    const instanceToken = canvas.refreshToken;
    const instanceData = {
      url: canvasConfig.canvasURL,
      clientId: canvasConfig.clientId,
      clientSecret: canvasConfig.clientSecret,
    };

    const scheduleSection = syllabus.sections.find(
      (section) => section.type === "Schedule"
    ) as Schedule;

    const filesUrls: any = [];

    scheduleSection.modules.forEach((module) => {
      module.activities.forEach((activity) => {
        activity.body.content?.forEach((content) => {
          content.content?.forEach((cont) => {
            cont.marks?.forEach((mark) => {
              if (mark.type === "link") {
                filesUrls.push({
                  url: `https:${mark.attrs?.href}`,
                  name: mark.attrs?.text,
                  activity: activity.title,
                  module: module.title,
                  type: activity.graded ? "assignment" : "activity",
                });
              }
            });
          });
        });
      });
    });

    const files = filesUrls.reduce((acc: any, d: any) => {
      const found = acc.find((a: { module: any }) => a.module === d.module);
      const value = {
        name: d.name,
        url: d.url,
        activity: d.activity,
        type: d.type,
      };
      if (!found) {
        acc.push({ module: d.module, data: [value] });
      } else {
        found.data.push(value);
      }
      return acc;
    }, []);

    if (instanceToken !== "") {
      const callableCreate = firebase
        .functions()
        .httpsCallable("exportToCanvas");
      const generateOutcomes = firebase
        .functions()
        .httpsCallable("generateOutcomes");
      const uploadFiles = firebase.functions().httpsCallable("uploadFiles");
      try {
        const [Course, Activities, Objectives] = generateHTML(
          "Modern",
          syllabus
        );
        setLoading(true);
        window.analytics.track("Generated Pages");
        const result = await callableCreate({
          syllabusId: syllabus.firebaseId,
          refreshToken: instanceToken,
          instanceData: instanceData,
          Course: Course,
          Activities: Activities,
          Objectives: Objectives,
          courseId: courseId,
        });

        if (result.data.status === "ok") {
          window.analytics.track("Created Canvas course");
          setCanvasId(result.data.data.courseId);
          await syllabusRepository.save(id!, {
            ...props.syllabus,
            preSelectCourseId: result.data.data.courseId.toString(),
          });
          props.onExport(courseId ?? result.data.data);
          const repository = new PublicSyllabusRepository();
          await repository.save(syllabus.firebaseId!, syllabus);
          window.analytics.track("Saved public version of syllabus");
          const url = `${canvasConfig.canvasURL}/courses/${result.data.data.courseId}`;
          window.analytics.track("Finished export to Canvas");
          window.open(url, "_blank");
          await generateOutcomes({
            syllabusId: syllabus.firebaseId,
            refreshToken: instanceToken,
            instanceData: instanceData,
            courseId: result.data.data.courseId,
            assignmentsIds: result.data.data.assignmentsArray,
          });

          const assignmentFiles: any = [];

          files.forEach((file: any) => {
            file.data.forEach((data: any) => {
              if (data.type === "assignment") {
                result.data.data.assignmentsArray.forEach(
                  (assignment: { title: string; id: string }) => {
                    if (data.activity === assignment.title) {
                      assignmentFiles.push({
                        module: file.module,
                        data: file.data,
                        assignmentId: assignment.id,
                      });
                    }
                  }
                );
              } else {
                result.data.data.activitiesArray.forEach(
                  (activity: { title: string; id: string }) => {
                    if (data.activity === activity.title) {
                      assignmentFiles.push({
                        module: file.module,
                        data: file.data,
                        assignmentId: activity.id,
                      });
                    }
                  }
                );
              }
            });
          });

          console.log("assignmentFiles", assignmentFiles);

          await uploadFiles({
            instanceData: instanceData,
            refreshToken: instanceToken,
            courseId: result.data.data.courseId,
            filesUrls: assignmentFiles,
          });

          dispatch(setPreSelectedCourse(result.data.data.courseId.toString()));
          setLoading(false);
        } else {
          throw new Error(result.data.message);
        }
      } catch (error) {
        alert("Error: " + error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <HidableComponent isVisible={isOpenDialog}>
        <CanvasExportModalDialog
          onExport={async (courseId) => {
            await onClickExportToCanvas(courseId);
          }}
          onClose={() => {
            if (loading) return;
            setIsOpenDialog(false);
            props.onClose();
          }}
          onClickCancel={() => props.onClickCancel?.()}
          syllabus={syllabus}
          opened={isOpenDialog}
          loading={loading}
          canvasId={canvasId}
        />
      </HidableComponent>
    </>
  );
};

export default CanvasExportModal;
