import React from "react";

import { Themes } from "../TemplateGallery";
import { ThemeList } from "../types";

import type { BaseTemplateProps } from "../types";

const ActivitiesBaseTemplate: React.FC<BaseTemplateProps> = (props) => {
  return (
    <>
      {(() => {
        switch (props.theme) {
          case ThemeList.Classic:
            return (
              <Themes.Classic.Activities
                body={props.PageData.body}
                title={props.PageData.title}
                objectives={props.PageData.objectives}
              />
            );
          case ThemeList.Modern:
            console.log("Modern Template Called: ", props.PageData);
            return (
              <Themes.Modern.Activities
                body={props.PageData.body}
                objectives={props.PageData.objectives}
                title={props.PageData.title}
              />
            );
          default:
            return;
        }
      })()}
    </>
  );
};

export default ActivitiesBaseTemplate;
