import React from "react";

import type { DescriptionProps } from "../../../../../types";

const Description: React.FC<DescriptionProps> = (props) => {
  return (
    <>
      <h1>Description</h1>
      <div>{props.description}</div>
    </>
  );
};

export default Description;
