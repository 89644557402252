import axios, { AxiosInstance, Method } from "axios";
import { LoginResponse } from "./dto/LoginResponse";
import GetUserByIdResponse from "./dto/GetUserByIdResponse";
import { courseMapConfig } from "../../config/config";
import CreateCourseResponse from "./dto/CreateCourseResponse";
import GetCourseByIdResponse from "./dto/GetCourseByIdResponse";

class CourseMapAPI {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: courseMapConfig.authUrl,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async call(method: Method, url: string, data: any = {}) {
    try {
      return await this.client.request({
        method,
        data,
        url,
      });
    } catch (e) {
      throw e;
    }
  }

  async authCall(method: Method, url: string, data: any = {}) {
    return await this.client.request({
      method,
      data,
      url,
      headers: {
        Authorization: `Bearer ${courseMapConfig.accessToken}`,
        // firebaseId: configureStore().getState().firebase.auth.uid,
      },
    });
  }

  async login(code: string): Promise<LoginResponse> {
    const queryString = new URLSearchParams({});
    const result = await this.call(
      "POST",
      `oauth/access_token?${queryString}`,
      {
        code: code,
        client_id: courseMapConfig.clientId,
        client_secret: courseMapConfig.clientSecret,
        redirect_uri: courseMapConfig.redirectUri,
      }
    );

    return result.data as LoginResponse;
  }

  async getUserById(id: string): Promise<GetUserByIdResponse> {
    const result = await this.authCall("GET", `/obj/user/${id}`);

    return result.data.response as GetUserByIdResponse;
  }

  async getCoursesByOwner(owner: string): Promise<GetCourseByIdResponse[]> {
    const result = await this.authCall("GET", `/obj/Course`, { Owner: owner });

    return result.data.response.results as GetCourseByIdResponse[];
  }

  async updateCourse(
    courseId: string,
    courseName: string,
    courseNumber: string
  ): Promise<any> {
    const result = await this.authCall("PATCH", `/obj/Course/${courseId}`, {
      Name: courseName,
      Number: courseNumber,
    });

    return result.data;
  }

  async createCourse(
    courseName: string,
    courseNumber: string,
    owner: string,
    canvasId: string
  ): Promise<CreateCourseResponse> {
    const result = await this.authCall("POST", "/obj/Course", {
      Name: courseName,
      Number: courseNumber,
      Owner: owner,
      canvasId: canvasId,
    });

    return result.data as CreateCourseResponse;
  }

  async updateUser(userId: string, canvasId: string): Promise<any> {
    const result = await this.authCall("PATCH", `/obj/User/${userId}`, {
      canvasId: canvasId,
    });

    return result.data;
  }

  async getAuthURL() {
    const queryString = new URLSearchParams({
      client_id: courseMapConfig.clientId,
      redirect_uri: courseMapConfig.redirectUri,
    });
    return `${courseMapConfig.authUrl}/oauth/authorize?${queryString}`;
  }
}

export default CourseMapAPI;
