import React from "react";

import { Themes } from "../TemplateGallery";
import { ThemeList } from "../types";

import type { BaseTemplateProps } from "../types";

const CourseBaseTemplate: React.FC<BaseTemplateProps> = (props) => {
  return (
    <>
      {(() => {
        switch (props.theme) {
          case ThemeList.Classic:
            return (
              <Themes.Classic.Course
                header={{
                  title: props.PageData.header.title,
                  number: props.PageData.header.number,
                }}
                instructors={{
                  instructors: props.PageData.instructors.instructors,
                }}
                officeHours={{
                  officeHours: props.PageData.officeHours.officeHours,
                }}
                description={{
                  description: props.PageData.description.description,
                }}
                objectives={{
                  objectives: props.PageData.objectives.objectives,
                }}
                schedule={{ weeks: props.PageData.schedule.weeks }}
                firebaseId={props.PageData.firebaseId}
              />
            );
          case ThemeList.Modern:
            return (
              <Themes.Modern.Course
                header={{
                  title: props.PageData.header.title,
                  number: props.PageData.header.number,
                }}
                instructors={{
                  instructors: props.PageData.instructors.instructors,
                }}
                description={{
                  description: props.PageData.description.description,
                }}
                objectives={{
                  objectives: props.PageData.objectives.objectives,
                }}
                schedule={{ weeks: props.PageData.schedule.weeks }}
                firebaseId={props.PageData.firebaseId}
              />
            );
          default:
            return;
        }
      })()}
    </>
  );
};

export default CourseBaseTemplate;
