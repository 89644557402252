import { CanvasActions, CanvasActionType } from "../types/CanvasTypes";

export interface CanvasState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  userId: string | undefined;
  scopes: string[] | undefined;
  preSelectedCourse: string;
  preStoredCourses: Array<{
    name: string;
    id: string;
  }>;
}

const initialState: CanvasState = {
  accessToken: undefined,
  refreshToken: undefined,
  userId: undefined,
  scopes: [],
  preSelectedCourse: "",
  preStoredCourses: [],
};

export const CanvasReducer = (
  state = initialState,
  action: CanvasActions
): CanvasState => {
  switch (action.type) {
    case CanvasActionType.SetTokensCanvas:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
        scopes: action.payload.scopes,
      };
    case CanvasActionType.ClearTokens:
      return initialState;
    case CanvasActionType.PreSelectedCourse:
      return {
        ...state,
        preSelectedCourse: action.payload,
      };
    case CanvasActionType.PreStoredCourses:
      return {
        ...state,
        preStoredCourses: action.payload,
      };
    default:
      return state;
  }
};
