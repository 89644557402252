import React, { useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import firebase from "firebase";
import { useLocation } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useAppDispatch } from "../../../../redux/configureStore";
import { setCanvasTokens } from "../../../../redux/integrations/actions/CanvasActions";
import LoadingAnimation from "../../../LoadingAnimation";
import { useQuery } from "../../../../hooks/useQuery";
import { canvasConfig } from "../../../../config/config";

const LoaderRedirect = (props: any) => {
  const location = useLocation();
  const firestore = useFirestore();
  const dispatch = useAppDispatch();
  const localFirebase = useFirebase();
  const query = useQuery();

  useEffect(() => {
    (async () => {
      const parsed = queryString.parse(location.search);
      if ((parsed.code as string) !== undefined) {
        const code = parsed.code as string;
        if ((parsed.state as string) !== undefined) {
          const state = parsed.state as string;
          if (state.includes("canvas")) {
            const callCanvasAuthenticate = firebase
              .functions()
              .httpsCallable("canvasAuthenticate");
            try {
              const result = await callCanvasAuthenticate({ code: code });
              const token = result.data.access_token as string;
              const refreshToken = result.data.refresh_token as string;
              const userId = result.data.user.id as string;
              dispatch(
                setCanvasTokens(
                  token,
                  refreshToken,
                  userId,
                  canvasConfig.scopes
                )
              );
            } catch (error) {
              console.log(error);
            }
            let syllabus = state.split("syllabusId=");
            let syllabusId = syllabus[1];
            let URL = window.location.href.split("loaderRedirect");
            const url = `${URL[0]}edit/${syllabusId}`;

            //@ts-ignore
            window.location = url;
          } else if (state.includes("LTILaunch")) {
            const callCanvasAuthenticate = firebase
              .functions()
              .httpsCallable("canvasAuthenticate");

            try {
              const result = await callCanvasAuthenticate({ code: code });
              const token = result.data.access_token as string;
              const refreshToken = result.data.refresh_token as string;
              const userId = result.data.user_id as string;
              dispatch(
                setCanvasTokens(
                  token,
                  refreshToken,
                  userId,
                  canvasConfig.scopes
                )
              );

              await localFirebase.updateProfile({
                canvasId: userId,
              });

              const courseName = query.get("name")!;
              const courseNumber = query.get("num")!;
              const courseId = query.get("id")!;
              const user = query.get("user")!;

              const callLtiCreateCourse = firebase
                .functions()
                .httpsCallable("ltiCreateCourse");

              try {
                await callLtiCreateCourse({
                  courseName: courseName,
                  courseId: courseId,
                  courseNumber: courseNumber,
                  user: user,
                  canvasUser: userId,
                });
              } catch (e) {
                console.log(e);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    })();
  }, [location.search, firestore, dispatch, query, localFirebase]);

  return <LoadingAnimation />;
};

LoaderRedirect.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default LoaderRedirect;
