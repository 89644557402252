import { generateHTML } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Link } from "@tiptap/extension-link";

import { transformValues } from "./transformValues";
import type { JSONContent } from "@tiptap/core";
import { Activity } from "model/Resource";

export const generateCanvasHtml = (content: JSONContent): string => {
  const parsedJsonContent = transformValues(
    content,
    (key: string, value: any) => value.type === "file-button",
    (value: any) => ({
      type: "paragraph",
      content: [
        {
          marks: [
            {
              type: "link",
              attrs: {
                target: "_blank",
                href: value.attrs.url,
              },
            },
          ],
          type: "text",
          text: value.attrs.name,
        },
      ],
    })
  );

  return generateHTML(parsedJsonContent, [StarterKit, Link]);
};

export const generateCanvasActivityHtml = (activity: Activity): string => {
  let parsedJsonContent = transformValues(
    activity.body,
    (key: string, value: any) => value.type === "file-button",
    (value: any) => ({
      type: "paragraph",
      content: [
        {
          marks: [
            {
              type: "link",
              attrs: {
                target: "_blank",
                href: value.attrs.url,
              },
            },
          ],
          type: "text",
          text: value.attrs.name,
        },
      ],
    })
  );

  if (activity.customText && activity.customText.length > 0) {
    return parsedJsonContent.content
      ? activity.customText +
          generateHTML(parsedJsonContent, [StarterKit, Link])
      : activity.customText;
  }

  return generateHTML(parsedJsonContent, [StarterKit, Link]);
};
