import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/configureStore";
import { useQuery } from "../hooks/useQuery";
import CourseMapAPI from "helpers/CourseMap/CourseMapAPI";
import { canvasConfig, courseMapConfig } from "../config/config";
import LoadingAnimation from "./LoadingAnimation";

const LTI: React.FC = () => {
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const canvas = useSelector((state: AppState) => state.integrations.canvas);
  const query = useQuery();
  const courseName = query.get("name")!;
  const courseNumber = query.get("num")!;
  const courseId = query.get("id")!;

  const api = new CourseMapAPI();

  useEffect(() => {
    (async () => {
      if (auth.uid !== undefined) {
        console.log("UID", auth.uid);
        console.log("CANVAS", canvas.accessToken);
        if (canvas.accessToken) {
          const getUserData = await api.getUserById(auth.uid);
          if (!getUserData.canvasId && canvas.userId) {
            await api.updateUser(auth.uid, canvas.userId);
          }
          let courseToUpdate: undefined | string = undefined;
          const result = await api.getCoursesByOwner(auth.uid);
          for (const courseData of result) {
            if (courseData.canvasId && courseData.canvasId === courseId) {
              courseToUpdate = courseData._id;
            }
          }
          if (courseToUpdate) {
            await api.updateCourse(courseToUpdate, courseName, courseNumber);
            window.location.replace(
              `${courseMapConfig.courseUrl}${courseToUpdate}`
            );
          } else {
            const createCourse = await api.createCourse(
              courseName,
              courseNumber,
              auth.uid,
              courseId
            );
            if (createCourse.status === "success") {
              window.location.replace(
                `${courseMapConfig.courseUrl}${createCourse.id}`
              );
            }
          }
        } else {
          const instanceData = {
            url: canvasConfig.canvasURL,
            clientId: canvasConfig.clientId,
            clientSecret: canvasConfig.clientSecret,
            scope: canvasConfig.scopes.join(" "),
          };
          const url = `${instanceData.url}/login/oauth2/auth?client_id=${instanceData.clientId}&response_type=code&state=LTILaunch=${courseId}name=${courseName}num=${courseNumber}user=${auth.uid}&redirect_uri=${canvasConfig.redirectToSyllabus}&scope=${instanceData.scope}`;
          // @ts-ignore
          window.location = url;
        }
      } else {
        window.location.replace(
          `/login?name=${courseName}&num=${courseNumber}&id=${courseId}`
        );
      }
    })();
  });

  return <LoadingAnimation />;
};

export default LTI;
