import React from "react";

import type { ScheduleProps } from "../../../../../types";

const Schedule: React.FC<ScheduleProps> = (props) => {
  return (
    <>
      <h1>Modules</h1>
      <div
        style={{
          display: "grid",
          gridGap: "10px",
          gridTemplateColumns: "auto auto auto auto",
        }}
      >
        {props.weeks.map((week, index) => {
          return (
            <div
              key={index}
              style={{ textAlign: "center", border: "solid 1px black" }}
            >
              <a href={`weekUrl-${index}`}>{week.title}</a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Schedule;
