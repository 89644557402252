import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Planner: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.97 2.02002H11.03C9.90886 2.02002 9 2.92888 9 4.05002V5.08002C9 6.20116 9.90886 7.11002 11.03 7.11002H12.97C14.0911 7.11002 15 6.20116 15 5.08002V4.05002C15 2.92888 14.0911 2.02002 12.97 2.02002Z"
          stroke="#0D3AF8"
          strokeWidth="1.93"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.98 16.02H5.02C3.90438 16.02 3 16.9244 3 18.04V19C3 20.1156 3.90438 21.02 5.02 21.02H6.98C8.09562 21.02 9 20.1156 9 19V18.04C9 16.9244 8.09562 16.02 6.98 16.02Z"
          stroke="#0D3AF8"
          strokeWidth="1.92"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.98 16.02H17.02C15.9044 16.02 15 16.9244 15 18.04V19.02C15 20.1356 15.9044 21.04 17.02 21.04H18.98C20.0956 21.04 21 20.1356 21 19.02V18.04C21 16.9244 20.0956 16.02 18.98 16.02Z"
          stroke="#0D3AF8"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 8.30005V10.44"
          stroke="#0D3AF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 10.4399H6.04004"
          stroke="#0D3AF8"
          strokeWidth="2.04"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.04004 12.9501V10.6001"
          stroke="#0D3AF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 12.9499V10.6799"
          stroke="#0D3AF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <clipPath id="clip0_180_4323">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Blank>
  );
};
export default Planner;
