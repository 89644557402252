import { match } from "react-router-dom";
import { Identifiable } from "utils/Identifiable";
import { useFirestore } from "react-redux-firebase";
import { useState, useEffect } from "react";
import PublicSyllabusRepository from "../../repositories/PublicSyllabusRepository";
import { Syllabus } from "model/Syllabus";
import SyllabusViewer from "./SyllabusViewer";
import React from "react";

interface CanvasSyllabusIdViewerProps {
  match: match<Identifiable>;
}

const CanvasSyllabusIdViewer: React.FC<CanvasSyllabusIdViewerProps> = (
  props
) => {
  const firestore = useFirestore();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const id = props.match.params.id;

  useEffect(() => {
    (async () => {
      try {
        const repository = new PublicSyllabusRepository();
        const syllabus = await repository.findById(id);
        setSyllabus(syllabus);
        window.analytics.track("Canvas Syllabus Requested", { syllabusId: id });
        setIsLoading(false);
      } catch (error) {
        window.analytics.track("Canvas Syllabus Not Found", { syllabusId: id });
        setIsLoading(false);
      }
    })();
  }, [firestore, id]);

  if (syllabus) {
    return <SyllabusViewer syllabus={syllabus} publicMode={true} />;
  } else if (isLoading) {
    return <div />;
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default CanvasSyllabusIdViewer;
