import React from "react";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import FileComponent from "../CustomComponents/FileComponent";
import { Link } from "@tiptap/extension-link";
import { Image } from "@tiptap/extension-image";
import { Underline } from "@tiptap/extension-underline";
import styled from "styled-components";

interface ActivitiesEditorViewerProps {
  value: JSONContent;
  id?: string;
}

const StyledEditorContent = styled(EditorContent)`
  border: 1px solid #e8f0fe;
  box-shadow: inset 0 0.5px 4px rgba(13, 34, 90, 0.15);
  border-radius: 4px;
  max-height: 300px;
  overflow-y: scroll;
  height: 300px;
  margin: 11px 0 0;
  padding: 15px;
  width: 87%;
  & .ProseMirror blockquote {
    background: #eee;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 0;
  }

  & .ProseMirror pre {
    background: #eee;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 8px 0;
  }

  & > div p {
    margin: 4px 0;
  }
`;

const ActivitiesEditorViewer: React.FC<ActivitiesEditorViewerProps> = (
  props
) => {
  const editor = useEditor({
    extensions: [StarterKit, FileComponent, Link, Image, Underline],
    editable: false,
    content: props.value,
  });
  return <StyledEditorContent editor={editor} />;
};

export default ActivitiesEditorViewer;
