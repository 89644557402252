// canvas
import { canvasConfig as canvasCommon } from "./configCommon";
import { canvasConfig as canvasDeploy } from "./configDeploy";

// stripe
import { stripeConfig as stripeCommon } from "./configCommon";
import { stripeConfig as stripeDeploy } from "./configDeploy";

// rff
import { rrfConfig as rffCommon } from "./configCommon";
import { rrfConfig as rffDeploy } from "./configDeploy";

// youtube
import { youtubeConfig as youtubeCommon } from "./configCommon";
import { youtubeConfig as youtubeDeploy } from "./configDeploy";

// google books
import { googleBooksConfig as googleBooksCommon } from "./configCommon";
import { googleBooksConfig as googleBooksDeploy } from "./configDeploy";

// google custom search
import { customSearchConfig as customSearchCommon } from "./configCommon";
import { customSearchConfig as customSearchDeploy } from "./configDeploy";

// firebase
import { firebaseConfig as firebaseCommon } from "./configCommon";
import { firebaseConfig as firebaseDeploy } from "./configDeploy";

//app
import { appConfig as appCommon } from "./configCommon";
import { appConfig as appDeploy } from "./configDeploy";

//webex
import { webexConfig as webexCommon } from "./configCommon";
import { webexConfig as webexDeploy } from "./configDeploy";

//teams
import { teamsConfig as teamsDeploy } from "./configDeploy";

//blackboard
import { blackboardConfig as blackboardCommon } from "./configCommon";
import { blackboardConfig as blackboardDeploy } from "./configDeploy";

// reflect
import { reflectConfig as reflectCommon } from "./configDeploy";

//courseMap
import { courseMapConfig as courseMapDeploy } from "./configDeploy";

//lti
import { ltiConfig as ltiDeploy } from "./configDeploy";

//functions
import { functionsConfig as functionsDeploy } from "./configDeploy";

// re-export
export const canvasConfig = { ...canvasCommon, ...canvasDeploy };
export const stripeConfig = { ...stripeCommon, ...stripeDeploy };
export const rrfConfig = { ...rffCommon, ...rffDeploy };
export const firebaseConfig = { ...firebaseCommon, ...firebaseDeploy };
export const youtubeConfig = { ...youtubeCommon, ...youtubeDeploy };
export const googleBooksConfig = { ...googleBooksCommon, ...googleBooksDeploy };
export const customSearchConfig = {
  ...customSearchCommon,
  ...customSearchDeploy,
};
export const appConfig = { ...appCommon, ...appDeploy };
export const webexConfig = { ...webexCommon, ...webexDeploy };
export const teamsConfig = teamsDeploy;
export const blackboardConfig = { ...blackboardCommon, ...blackboardDeploy };
export const reflectConfig = reflectCommon;
export const courseMapConfig = courseMapDeploy;
export const ltiConfig = ltiDeploy;
export const functionsConfig = functionsDeploy;
