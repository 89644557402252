import React from "react";

import type { OfficeHoursProps } from "../../../../../types";

const OfficeHours: React.FC<OfficeHoursProps> = (props) => {
  return (
    <>
      <h1>Office Hours</h1>
      <p>{props.officeHours}</p>
    </>
  );
};

export default OfficeHours;
