import React from "react";

import type { ObjectivesProps } from "../../../../../types";

const Objectives: React.FC<ObjectivesProps> = (props) => {
  return (
    <>
      <h1>Objectives</h1>
      <ul>
        {props.objectives?.map((objective) => (
          <li key={objective.id}>{objective.title}</li>
        ))}
      </ul>
    </>
  );
};

export default Objectives;
