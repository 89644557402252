import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from "firebase";
import styled from "styled-components";
import UsersRepository from "../repositories/UsersRepository";
import { useFirebase } from "react-redux-firebase";
import { courseMapConfig } from "../config/config";
import LoadingAnimation from "./LoadingAnimation";

interface BubbleAuthProps {}

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
`;

const BubbleAuth: React.FC<BubbleAuthProps> = (props) => {
  const location = useLocation();
  const localFirebase = useFirebase();
  const code = new URLSearchParams(location.search).get("code") as string;

  const loginWithCode = useCallback(
    async (code: string) => {
      window.analytics.track("Login with Code");

      const callableCreate = firebase
        .functions()
        .httpsCallable("loginWithCode");
      const response = await callableCreate({
        code,
      });

      window.analytics.track("Sign user with custom token");

      if (response.data.status === "ok") {
        const repository = new UsersRepository();
        const auth = await firebase
          ?.auth()
          .signInWithCustomToken?.(response.data.data.token);

        window.analytics.track("Redirect to plan url");

        // @ts-ignore
        window.location = `${courseMapConfig.homeUrl}`;

        const user = await repository.getById(auth.user!.uid!);

        window.analytics.track("Update User Profile");

        await localFirebase.updateProfile({
          ...user,
        });
      } else {
        alert(response.data.message);
      }
    },
    [localFirebase]
  );

  useEffect(() => {
    loginWithCode(code);
  }, [loginWithCode, code]);

  return (
    <StyledDiv>
      <LoadingAnimation />
    </StyledDiv>
  );
};

export default BubbleAuth;
