import React, { useCallback, useEffect, useState, Fragment } from "react";
import {
  allSectionTypes,
  CustomSection,
  getSectionTypeLabel,
  isUniqueSection,
  Section,
  SectionType,
  Syllabus,
} from "../../model/Syllabus";
import update from "immutability-helper";
import SyllabusEditorSidebarItemDraggable from "./SyllabusEditorSidebarItemDraggable";
import CreationNavButton from "../ui-kit/CreationNavButton/CreationNavButton";

interface SyllabusEditorSidebarContainerProps {
  syllabus: Syllabus;
  onSectionsChange: (sections: Section[]) => void;
  onSectionAdd?: (sectionType: SectionType) => void;
  onSectionEdit?: (section: Section, type?: SectionType) => void;
  onModuleAdd?: () => void;
}

const SyllabusEditorSidebarContainer: React.FC<
  SyllabusEditorSidebarContainerProps
> = (props) => {
  const { onSectionsChange } = props;
  const [sections, setSections] = useState<Section[]>(
    props.syllabus.sections || []
  );

  useEffect(() => {
    setSections(props.syllabus.sections);
  }, [props.syllabus.sections]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = sections[dragIndex];
      setSections((oldSections) => {
        const newSections = update(oldSections, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        });
        onSectionsChange(newSections);
        return newSections;
      });
    },
    [sections, onSectionsChange]
  );

  const sectionAlreadyExistInSyllabus = (
    el: SectionType,
    syllabus: Syllabus
  ) => {
    return syllabus.sections
      .map((section) => section.type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter((type) =>
        isUniqueSection(
          type as
            | "Instructors"
            | "CourseDescription"
            | "CourseObjectives"
            | "CourseTitle"
            | "CreditHours"
            | "ClassTimes"
            | "OfficeHours"
            | "GradingPolicy"
            | "CustomSection"
            | "Schedule"
            | "Skills"
            | "Prerequisites"
            | "Location"
            | "Materials"
            | "Module"
        )
      )
      .includes(el);
  };

  return (
    <>
      {sections.map((section, index) => {
        const label = getSectionTypeLabel(
          section.type as
            | "Instructors"
            | "CourseDescription"
            | "CourseObjectives"
            | "CourseTitle"
            | "CreditHours"
            | "ClassTimes"
            | "OfficeHours"
            | "GradingPolicy"
            | "CustomSection"
            | "Schedule"
            | "Skills"
            | "Prerequisites"
            | "Location"
            | "Materials"
            | "Module"
        );
        if (label !== "Section not found") {
          return (
            <Fragment key={`label-${index}`}>
              <SyllabusEditorSidebarItemDraggable
                key={(section as any).id}
                id={(section as any).id}
                index={index}
                // onClick={() => onSectionEdit?.(type, index)}
                text={
                  section.type === "CustomSection"
                    ? (section as CustomSection).title?.replace(
                        /(.{16})..+/,
                        "$1…"
                      ) || label
                    : label
                }
                added={true}
                onClick={() => {
                  props.onSectionEdit?.(section);
                }}
                moveSidebarItemHandler={moveCard}
              />
              {section.type === "Schedule" && (
                <>
                  {section.modules.map((module) => (
                    <CreationNavButton
                      week={true}
                      added={true}
                      key={`sidebar-item-mini-${module.id}`}
                      id={`sidebar-item-mini-${module.id}`}
                      text={`${module.title}`}
                      onClick={() => {
                        props.onSectionEdit?.(module);
                      }}
                    />
                  ))}
                  {/*<CreationNavButton
                    week={true}
                    added={false}
                    key={`sidebar-item-mini-add-module`}
                    id={`sidebar-item-mini-add-module`}
                    text="Module"
                    onClick={() => {
                      props.onModuleAdd?.();
                    }}
                  />*/}
                </>
              )}
            </Fragment>
          );
        }
        return "";
      })}
      {allSectionTypes
        //only allow unique sections to be added once
        .filter(
          (section) => !sectionAlreadyExistInSyllabus(section, props.syllabus)
        )
        .sort((t1, t2) =>
          getSectionTypeLabel(t1).localeCompare(getSectionTypeLabel(t2))
        )
        .map((type, index) => {
          const label = getSectionTypeLabel(type);
          return (
            <CreationNavButton
              key={`type-${index}`}
              text={label}
              added={false}
              onClick={() => props.onSectionAdd?.(type)}
            />
          );
        })}
    </>
  );
};

export default SyllabusEditorSidebarContainer;
