import React, { useEffect, useState } from "react";
import { match } from "react-router-dom";
import { Syllabus } from "../../../model/Syllabus";
import { useFirestore } from "react-redux-firebase";
import { Identifiable } from "utils/Identifiable";
import PublicSyllabusRepository from "../../../repositories/PublicSyllabusRepository";
import ModulePage from "./ModulePage";
import SyllabusThemeContextProvider from "../customization/SyllabusTheme";

interface CanvasModulePageViewerProps {
  match: match<Identifiable>;
}

const CanvasModulePageViewer: React.FC<CanvasModulePageViewerProps> = ({
  match,
}) => {
  const firestore = useFirestore();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const id = match.params.id;
  const index: number = match.params.index;

  useEffect(() => {
    (async () => {
      try {
        const repository = new PublicSyllabusRepository();
        const syllabus = await repository.findById(id);
        setSyllabus(syllabus);
        window.analytics.track("Module Requested", {
          syllabusId: id,
          moduleIndex: index,
        });
      } catch (error) {
        window.analytics.track("Module Not Found", {
          syllabusId: id,
          moduleIndex: index,
        });
      }
    })();
  }, [firestore, id, index]);

  if (syllabus) {
    return (
      <SyllabusThemeContextProvider
        fontFamily={syllabus.customization.fontFamily}
        primaryColor={syllabus.customization.primaryColor}
      >
        <ModulePage syllabus={syllabus} moduleIndex={index} unlocked={true} />
      </SyllabusThemeContextProvider>
    );
  } else {
    return <div />;
  }
};

export default CanvasModulePageViewer;
