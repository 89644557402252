import React from "react";

import type { ObjectivesPageProps } from "../../../../types";

const ObjectivesPage: React.FC<ObjectivesPageProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        paddingBottom: "30px",
      }}
    >
      {props.objectives.length > 0 && (
        <div
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid #d0dcf1",
            borderRadius: "6px",
            padding: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "27px",
              color: "#030b1d",
            }}
          >
            Week Objectives
          </span>
          <ul style={{ marginTop: "12px" }}>
            {props.objectives.map((objective) => {
              return (
                <li
                  key={objective.id}
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#030b1d",
                  }}
                >
                  {objective.title}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {props.assignmentPages.length > 0 &&
        props.assignmentPages.map((assignmentPage, i) => {
          return (
            <a
              href={`assignmentPage-${assignmentPage.index}`}
              style={{ textDecoration: "none" }}
            >
              <div
                key={assignmentPage.index}
                style={{
                  marginTop: "15px",
                  backgroundColor: "#ffffff",
                  border: "1px solid #d0dcf1",
                  borderRadius: "6px",
                  padding: "20px",
                }}
              >
                <h2
                  style={{
                    fontWeight: "600",
                    fontSize: "24px",
                    lineHeight: "30px",
                    color: "#193da9",
                  }}
                >
                  {i + 1} : {assignmentPage.title}
                </h2>
              </div>
            </a>
          );
        })}
    </div>
  );
};

export default ObjectivesPage;
