import { CourseMapActions, CourseMapActionType } from "../types/CourseMapTypes";
import { LoginCredentials } from "redux/integrations/types/CourseMapTypes";

export interface CourseMapState {
  credentials?: LoginCredentials;
}

const initialState: CourseMapState = {
  credentials: undefined,
};

export const CourseMapReducer = (
  state = initialState,
  action: CourseMapActions
): CourseMapState => {
  switch (action.type) {
    case CourseMapActionType.SaveLoginCredentials:
      return {
        ...state,
        credentials: action.payload,
      };
    default:
      return state;
  }
};
