export enum CanvasActionType {
  SetTokensCanvas = "SetTokensCanvas",
  ClearTokens = "ClearTokens",
  PreSelectedCourse = "PreSelectedCourse",
  PreStoredCourses = "PreStoredCourses",
}

export interface SetTokens {
  type: typeof CanvasActionType.SetTokensCanvas;
  payload: {
    refreshToken?: string;
    accessToken?: string;
    userId?: string;
    scopes?: string[];
  };
}

export interface ClearTokens {
  type: typeof CanvasActionType.ClearTokens;
  payload: undefined;
}

export interface PreSelectedCourse {
  type: typeof CanvasActionType.PreSelectedCourse;
  payload: string;
}

export interface PreStoredCourses {
  type: typeof CanvasActionType.PreStoredCourses;
  payload: Array<{
    name: string;
    id: string;
  }>;
}

export type CanvasActions =
  | SetTokens
  | ClearTokens
  | PreSelectedCourse
  | PreStoredCourses;
