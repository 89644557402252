import React, { useState } from "react";
import { Box, DropButton, Paragraph, Text } from "grommet";
import { CourseTitle, Syllabus } from "model/Syllabus";
import styled from "styled-components";
import SquaredButton from "components/ui-kit/SquaredButton/SquaredButton";
import SignUpModal from "components/one-week-plan/SignUpModal/SignUpModal";
import SyllabusRepository from "repositories/SyllabusRepository";
import { deleteTempSyllabus } from "redux/syllabus_templates/actions/SyllabusTemplateActions";
import { AppState, useAppDispatch } from "redux/configureStore";
import { useHistory } from "react-router-dom";
import useTemporalTemplate from "hooks/useTemporalTemplate";
import { useSelector } from "react-redux";
import {
  appConfig,
  courseMapConfig,
  functionsConfig,
} from "../../../config/config";
import Publisher from "../../../assets/icons/Publisher";
import Planner from "assets/icons/Planner";
import Tracker from "../../../assets/icons/Tracker";
import DropItem from "../../DropItem";
import axios from "axios";
import ArrowPrevious from "../../../assets/icons/ArrowPrevious";
import PublicSyllabusRepository from "../../../repositories/PublicSyllabusRepository";

interface SyllabusEditorHeaderProps {
  syllabus: Syllabus;
  onClickPreview?: () => void;
  onClickPublish?: () => void;
  onClickStudentView?: () => void;
  onClickPasswordManager?: () => void;
  onClickCopyPassword?: (link: string) => void;
  onClickTeacherPublish?: () => void;
  onClickCopyTeacherLink?: (link: string) => void;
  onClickExportToPDF?: () => void;
  onClickExportToCanvas?: () => void;
  onClickGenerateSharingLink?: () => void;
  needsRegistration?: boolean;
}

const Container = styled(Box)`
  padding: 32px 0;
  width: 100%;
`;

const StepText = styled.p`
  font-size: 14px;
  font-family: "Open Sans";
  margin: 5px 0 !important;
  color: #0d3af8;
  font-weight: 700;
`;

const Line = styled(Box)`
  width: 80px;
  border: 1px solid #0d3af8;
  height: 1px;
  margin-top: -30px;
`;

const Title = styled(Text)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #193da9;
`;

const Description = styled(Paragraph)`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #474e61;
  padding-bottom: 26px;
`;

const StyledDropItemWrapper = styled.div`
  max-height: 150px;
`;

const StyledDropItem = styled(DropItem)`
  height: 22px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #030b1d;
`;

const StyledDropButton = styled(DropButton)`
  font-family: Open Sans;
  font-weight: 600;
  line-height: 22px;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  color: #ffffff;
  background: #0d3af8;
  box-shadow: 0 2px 4px rgba(7, 26, 67, 0.25);
  border: 1.5px solid transparent;
  height: 40px;
  font-size: 16px;
  padding: 0 36px;

  &:hover {
    background: #2256f2;
    box-shadow: 0 3px 8px rgba(7, 26, 67, 0.35);
  }

  &:active {
    background: #1141d3;
    border: 1.5px solid #193da9;
    box-shadow: none;
  }
`;

const SidebarHeaderBackText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  color: #193da9;

  &:hover {
    cursor: pointer;
  }

  div {
    align-self: center;
  }

  p {
    padding-left: 22px;
  }
`;

const SyllabusEditorPrivateHeader: React.FC<SyllabusEditorHeaderProps> = (
  props
) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const reduxSyllabi: Syllabus[] = useTemporalTemplate();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const titleSection = props.syllabus.sections.find(
    (section) => section.type === "CourseTitle"
  ) as CourseTitle | undefined;

  const renderSignUpModal = () => (
    <SignUpModal
      opened={openModal}
      closeButton={true}
      handleClose={() => setOpenModal(false)}
      onNewLogin={(userId) => {
        reduxSyllabi.map(async (syllabus) => {
          const newSyllabus = {
            ...syllabus,
            createdBy: userId,
          } as Syllabus;
          window.analytics.track(`Successful signup`, { id: userId });
          window.analytics.identify(auth.uid, {
            email: auth.email,
          });
          window.analytics.track("Saved User Email");
          const syllabusRepository = new SyllabusRepository();
          await syllabusRepository.create(newSyllabus!);
          dispatch(deleteTempSyllabus(syllabus.parentId!));
        });
        history.replace("/dashboard");
      }}
    />
  );

  const printCourseMap = async () => {
    const data = { courseId: props.syllabus.courseMapId };
    const create = await axios.post(
      `${functionsConfig.url}createCourseMapPDF`,
      data
    );
    window.open(create.data, "_blank");
  };

  const printSyllabus = async () => {
    const data = { courseId: props.syllabus.courseMapId };
    const create = await axios.post(
      `${functionsConfig.url}createSyllabusPDF`,
      data
    );
    window.open(create.data, "_blank");
  };

  const createPublicLink = async () => {
    const repository = new PublicSyllabusRepository();
    await repository.save(props.syllabus.firebaseId!, props.syllabus);
    window.open(`${appConfig.completeUrl}c/${props.syllabus.firebaseId}`);
  };

  return (
    <>
      <Container gap="120px" align="center" direction="row">
        <SidebarHeaderBackText
          tabIndex={1}
          onClick={() =>
            window.location.assign(
              `${courseMapConfig.dashUrl}${props.syllabus.courseMapId}`
            )
          }
        >
          <div>
            <ArrowPrevious />
          </div>
          <p>{titleSection?.title}</p>
        </SidebarHeaderBackText>
        <Box
          gridArea="nav"
          direction="row"
          gap="25px"
          justify="center"
          align="center"
        >
          <Box
            justify="center"
            align="center"
            onClick={() =>
              window.location.assign(
                `${courseMapConfig.courseUrl}${props.syllabus.courseMapId}`
              )
            }
          >
            <Planner color="#0D3AF8" />
            <StepText>Planner</StepText>
          </Box>
          <Line />
          <Box
            justify="center"
            align="center"
            onClick={() =>
              window.location.assign(
                `${courseMapConfig.projectUrl}${props.syllabus.courseMapId}`
              )
            }
          >
            <Tracker color="#0D3AF8" />
            <StepText>Tracker</StepText>
          </Box>
          <Line />
          <Box justify="center" align="center">
            <Publisher color="#0D3AF8" />
            <StepText>Publisher</StepText>
          </Box>
        </Box>
      </Container>
      {renderSignUpModal()}
      <Box direction="row" justify="between" align="center">
        <Box>
          <Title>Publisher</Title>
          <Description>
            Finish building your Syllabus with the contents you’ve created in
            the previous steps.
          </Description>
        </Box>
        <Box gridArea="nav" direction="row" gap="large">
          <SquaredButton type="secondary" onClick={() => createPublicLink()}>
            Publish
          </SquaredButton>
          <StyledDropButton
            dropAlign={{ top: "bottom", right: "right" }}
            dropContent={
              <StyledDropItemWrapper>
                <StyledDropItem
                  text="Print Syllabus"
                  onClick={() => printSyllabus()}
                />
                <StyledDropItem
                  text="Print Course Map"
                  onClick={() => printCourseMap()}
                />
              </StyledDropItemWrapper>
            }
            label="Print"
          ></StyledDropButton>
        </Box>
      </Box>
    </>
  );
};

export default SyllabusEditorPrivateHeader;
