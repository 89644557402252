import React from "react";

import type { ButtonProps } from "../../../../../types";
import { appConfig } from "../../../../../../../config/config";

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <div style={{ height: "40px", width: "265px", margin: "24px 0px" }}>
      <p
        style={{
          backgroundColor: "#0d3af8",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "9px 24px 9px 24px",
          gap: "8px",
        }}
      >
        <a
          href={`${appConfig.completeUrl}c/${props.firebaseId}`}
          style={{
            color: "#ffffff",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "22px",
            textDecoration: "none",
          }}
          className={"external instructure_file_link inline_disabled"}
        >
          Complete BlendEd Syllabus
        </a>
      </p>
    </div>
  );
};

export default Button;
