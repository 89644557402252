import React from "react";

import type { ObjectivesPageProps } from "../../../../types";

const ObjectivesPage: React.FC<ObjectivesPageProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      <div
        style={{
          backgroundColor: "#ece4e4",
          borderStyle: "solid",
          borderColor: "#5991a6",
          padding: "5px",
          margin: "10px",
        }}
      >
        <h1>
          <i className="icon-announcement"></i>Learning Objectives
        </h1>
        <ul>
          {props.objectives.map((objective) => (
            <li key={objective.id}>{objective.title}</li>
          ))}
        </ul>
      </div>
      {props.assignmentPages.length > 0 && (
        <div
          style={{
            backgroundColor: "#ece4e4",
            borderStyle: "solid",
            borderColor: "#5991a6",
            padding: "5px",
            margin: "10px",
          }}
        >
          <h1>
            <i className="icon-announcement"></i>Assignments
          </h1>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "center" }}>
              {props.assignmentPages.map((assignmentPage) => (
                <div key={assignmentPage.index}>
                  <a href={`assignmentPage-${assignmentPage.index}`}>
                    {assignmentPage.title}
                  </a>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectivesPage;
