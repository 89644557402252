import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/configureStore";
import { useHistory } from "react-router-dom";
import { Syllabus } from "../model/Syllabus";
import { useFirestore } from "react-redux-firebase";
import { useGetMySyllabi } from "../hooks/useGetSyllabus";
import { setEditMode } from "../redux/common/actions/CommonActions";
import SyllabusListViewer from "./SyllabusListViewer";
import { courseMapConfig } from "../config/config";

/**
 * The dashboard component for listing a users available common.  These are pulled from firebase using the useGetMySyllabi hook.  Clicking the 'Add Syllabus' button opens a modal to either direct users to the template gallery or input basic information for creating a new syllabus.
 */
const SyllabusList: React.FC = () => {
  const history = useHistory();
  const firestore = useFirestore();
  const [syllabi, , , getAllSyllabi] = useGetMySyllabi();
  const [, setOpenDialog] = useState(false);
  const dispatch = useAppDispatch();

  /*useEffect(() => {
    if (profile.role === "Student") {
      history.push("/students");
    }
  }, [profile, history]);*/

  useEffect(() => {
    window.analytics.track("Redirect to plan url");
    window.location.replace(`${courseMapConfig.dashUrl}`);
  });

  const handleSave = async (emptySyllabus: Syllabus) => {
    try {
      const docRef = await firestore.collection("syllabi").add({
        ...emptySyllabus,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
      const doc = await docRef.get();
      window.analytics.track("Successfully created a Syllabus", {
        syllabusId: doc.id,
      });
      history.push("/edit/" + doc.id);
    } catch (error) {
      window.analytics.track("Creating a Syllabus Failed");
      console.log(error.message);
    }
    dispatch(setEditMode(true));
    setOpenDialog(false);
  };

  return (
    <>
      <SyllabusListViewer
        syllabi={syllabi}
        handleSave={(emptySyllabus) => handleSave(emptySyllabus)}
        onDeleteSyllabus={async (syllabusId) => {
          await firestore.collection("syllabi").doc(syllabusId).delete();
          window.analytics.track("Deleted Syllabus", {
            syllabusId: syllabusId,
          });
          await getAllSyllabi();
        }}
      />
    </>
  );
};

export default SyllabusList;
