import React from "react";

import type { ObjectivesProps } from "../../../../../types";

const Objectives: React.FC<ObjectivesProps> = (props) => {
  return (
    <div style={{ margin: "16px 0px" }}>
      <h1
        style={{
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "25px",
          color: "#030b1d",
        }}
      >
        Objectives
      </h1>
      <ul
        style={{
          marginTop: "16px",
          fontWeight: "400",
          fontSize: "12pt",
          lineHeight: "19px",
          color: "#474e61",
        }}
      >
        {props.objectives?.map((objective) => (
          <li key={objective.id}>{objective.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default Objectives;
