import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/configureStore";
import { useParams } from "react-router-dom";
import SyllabusRepository from "../../repositories/SyllabusRepository";
import { canvasConfig } from "../../config/config";
import firebase from "firebase";
import {
  setPreSelectedCourse,
  setPreStoredCourses,
} from "../../redux/integrations/actions/CanvasActions";
import CustomModal from "../modals/CustomModal";
import { HidableComponent } from "../hoc/Hidable";
import { Box, Select } from "grommet";
import Alert from "../Alert";
import LoadingAnimation from "../LoadingAnimation";
import { Syllabus } from "../../model/Syllabus";
import styled from "styled-components";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import Link from "../ui-kit/Link/Link";

interface SyllabusCanvasExportDialogProps {
  onClose: () => void;
  onClickCancel?: () => void;
  onExport: (id: string | undefined) => void;
  opened: boolean;
  syllabus: Syllabus;
  loading?: boolean;
  canvasId?: string;
}

const StyledRoundedButton = styled(RoundedButton)`
  width: 292px;
  padding: 0 !important;
  align-self: center;
  margin-top: 38px;
`;

const GoToMyCanvasSyllabusLink = styled(Link)`
  text-align: center;
`;
const ModalWrapper = styled.div`
  .primary-text {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #193da9;
  }

  .secondary-text {
    font-family: "Open Sans", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #474e61;
  }

  .reset-password {
    font-size: 12px;
    color: #2256f2;
    text-align: right;

    & span:hover,
    :focus-visible {
      cursor: pointer;
      color: #0d3af8;
    }
  }
`;

interface CanvasCourseListItem {
  name: string;
  id: string;
}

const CanvasExportModalDialog: React.FC<SyllabusCanvasExportDialogProps> = (
  props
) => {
  const [showWarning, setShowWarning] = useState(false);
  const [hasCourses, setHasCourses] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emptyList, setEmptyList] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();
  const syllabusRepository = new SyllabusRepository();

  const canvas = useAppSelector((state) => state.integrations.canvas);

  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [courseItems, setCourseItems] = useState<CanvasCourseListItem[]>([]);
  const [defaultItems, setDefaultItems] = useState<CanvasCourseListItem[]>([]);

  useEffect(() => {
    if (emptyList) {
      setLoading(false);
    }
  }, [emptyList]);

  const getCanvasCourse = useCallback(async () => {
    const data = {
      url: canvasConfig.canvasURL,
      clientId: canvasConfig.clientId,
      clientSecret: canvasConfig.clientSecret,
      instanceToken: canvas.refreshToken,
      scope: canvasConfig.scopes.join(" "),
      userId: canvas.userId,
    };
    let courseId = props.syllabus.firebaseId;
    const callableMyCanvasCourses = firebase
      .functions()
      .httpsCallable("getMyCanvasCourses");
    setLoading(true);
    const response = await callableMyCanvasCourses(data);
    setLoading(false);
    if (response.data.status === 401) {
      const url = `${data.url}/login/oauth2/auth?client_id=${data.clientId}&response_type=code&state=canvassyllabusId=${courseId}&redirect_uri=${canvasConfig.redirectToSyllabus}&scope=${data.scope}`;
      // @ts-ignore
      window.location = url;
    }

    if (
      response.data.data.length < 0 ||
      !response.data.data ||
      response.data.message !== "ok"
    ) {
      setEmptyList(true);
      return [];
    }

    return response.data.data.map((course: any) => ({
      name: course.name,
      id: course.id,
    }));
  }, [canvas.refreshToken, props.syllabus.firebaseId, canvas.userId]);

  useEffect(() => {
    (async () => {
      const syllabusRepository = new SyllabusRepository();
      const syllabus = await syllabusRepository.findById(id!);
      const preSelectedIdFromSyllabus = syllabus.preSelectCourseId;
      setSelectedCourse(
        courseItems.find(
          (course) => course.id.toString() === preSelectedIdFromSyllabus
        )
      );
      window.analytics.track("Got preselected course");
    })();
  }, [courseItems, id]);

  useEffect(() => {
    (async () => {
      let courses: Array<CanvasCourseListItem> = [];
      if (canvas.refreshToken) {
        if (canvas.preStoredCourses?.length === 0) {
          courses = await getCanvasCourse();
          courses.length > 0 && setHasCourses(true);
          dispatch(setPreStoredCourses(courses));
          window.analytics.track("Got Canvas Courses");
          setCourseItems(courses);
          setDefaultItems(courses);
        } else {
          setCourseItems(canvas.preStoredCourses);
          setDefaultItems(canvas.preStoredCourses);
          setHasCourses(true);
        }
      }
    })();
  }, [canvas.refreshToken, canvas.preStoredCourses, dispatch, getCanvasCourse]);

  const handleChange = (canvasCourse: any) => {
    setShowWarning(!!canvasCourse);
    const newCanvasCourse = { ...canvasCourse };
    setSelectedCourse(newCanvasCourse);
    dispatch(setPreSelectedCourse(canvasCourse.id.toString()));
  };

  const exportSelectedSyllabus = async () => {
    props.onExport(selectedCourse?.id);
    dispatch(setPreSelectedCourse(selectedCourse?.id.toString()));
    await syllabusRepository.save(id!, {
      ...props.syllabus,
      preSelectCourseId: canvas.preSelectedCourse,
    });
  };

  const exportSyllabus = async () => {
    props.onExport(undefined);
  };

  return (
    <CustomModal
      handleClose={() => props.onClose()}
      opened={props.opened}
      size="regular"
      closeButton={true}
      zIndex="1400"
    >
      <ModalWrapper>
        <>
          <p className="primary-text">Publish to Canvas</p>
          <p className="secondary-text">
            That's it, the hard parts over. We have it from here. Just select
            the course in Canvas you want us to build or create a new one.
          </p>
        </>
        <HidableComponent isVisible={!props.loading && !loading}>
          <Box alignContent="center" justify="center">
            <Box width="425px" alignSelf="center">
              <HidableComponent isVisible={hasCourses || !emptyList}>
                <Select
                  id="canvas-course-select"
                  name="canvas course select"
                  placeholder="Select a course from Canvas"
                  labelKey="name"
                  valueKey={{ key: "id" }}
                  onChange={({ value: nextValue }) => handleChange(nextValue)}
                  options={courseItems}
                  value={selectedCourse}
                  onSearch={(text: string) => {
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      "\\$&"
                    );
                    const exp = new RegExp(escapedText, "i");
                    setCourseItems(
                      defaultItems.filter((o) => exp.test(o.name))
                    );
                  }}
                  size="medium"
                  onClose={() => setCourseItems(defaultItems)}
                />
                <HidableComponent isVisible={showWarning}>
                  <Alert>
                    Are you sure you want to proceed? This will append all
                    syllabus data to the existing course in Canvas.
                  </Alert>
                </HidableComponent>
              </HidableComponent>
              <Box alignContent="center" justify="evenly">
                <StyledRoundedButton
                  disabled={!selectedCourse || emptyList}
                  onClick={exportSelectedSyllabus}
                  type="secondary"
                >
                  Overwrite This Course
                </StyledRoundedButton>
                <StyledRoundedButton onClick={exportSyllabus} type="primary">
                  Create A New Course
                </StyledRoundedButton>
                <HidableComponent isVisible={!!props.canvasId}>
                  <hr />
                  <GoToMyCanvasSyllabusLink
                    target="_blank"
                    href={`${canvasConfig.canvasURL}/courses/${props.canvasId}`}
                  >
                    Go to my canvas course
                  </GoToMyCanvasSyllabusLink>
                </HidableComponent>
              </Box>
            </Box>
          </Box>
        </HidableComponent>
        <HidableComponent isVisible={!!props.loading || loading}>
          <LoadingAnimation />
        </HidableComponent>
      </ModalWrapper>
    </CustomModal>
  );
};

export default CanvasExportModalDialog;
