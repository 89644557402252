import React, { useContext } from "react";
import { CourseObjectives } from "../../../model/Syllabus";
import H4 from "../../viewer/customization/H4";
import styled from "styled-components";
import { Box, Text } from "grommet";
import { SyllabusThemeContext } from "../../viewer/customization/SyllabusTheme";

interface ObjectivesSectionProps {
  section: CourseObjectives;
}

const ObjectiveTitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: #030b1d !important;
  margin-bottom: 2px !important;
`;

// CoursePage Objectives viewer component
const ObjectivesSection: React.FC<ObjectivesSectionProps> = (props) => {
  const section = props.section;
  const { fontFamily } = useContext(SyllabusThemeContext);

  return (
    <>
      <H4 a11yTitle={"Objectives Section"}>Course Objectives</H4>
      <ul>
        {section.objectives.length > 0 &&
          section.objectives.map((objective, index) => {
            return (
              <li key={index}>
                <Box
                  key={"objective-" + index}
                  direction="column"
                  margin={{ bottom: "xsmall" }}
                >
                  <ObjectiveTitle style={{ fontFamily: fontFamily }}>
                    {objective.title}
                  </ObjectiveTitle>
                </Box>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default ObjectivesSection;
