import React, { useContext } from "react";
import { Module } from "../../../model/Syllabus";
import ArrowLeftIcon from "../../../assets/icons/ArrowPrevious";
import ArrowRightIcon from "../../../assets/icons/ArrowNext";
import styled from "styled-components";
import { addDays } from "date-fns";
import { Keyboard } from "grommet";
import { SyllabusThemeContext } from "../customization/SyllabusTheme";

const StyledHeader = styled.div`
  font-family: "Open Sans";
`;

const StyledNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 35px;
`;

const HeaderNavText = styled.div`
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  margin-top: 8px;
  display: flex;
  color: #030b1d;
  justify-content: center;
  align-items: center;

  img:hover,
  p:hover {
    cursor: pointer;
  }

  p {
    padding: 0 22px;
  }

  img.right {
    transform: rotate(180deg);
  }

  h1 {
    text-align: center;
    color: ${(props) => props.color};
    font-weight: 700;
    font-size: 18px;
  }
`;

interface ModuleHeaderProps {
  module: Module;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  next?: boolean;
  prev?: boolean;
}

const ModuleHeader: React.FC<ModuleHeaderProps> = ({
  module,
  onClickPrev,
  onClickNext,
  next,
  prev,
}) => {
  const { primaryColor } = useContext(SyllabusThemeContext);

  return (
    <StyledHeader role={"Module by Module Navigation"}>
      <StyledNav>
        {prev ? (
          <Keyboard onEnter={onClickPrev}>
            <HeaderNavText onClick={onClickPrev} tabIndex={2}>
              <ArrowLeftIcon />
              <p>{prev ? addDays(module.end_at, -7).toDateString() : ""}</p>
            </HeaderNavText>
          </Keyboard>
        ) : (
          <div />
        )}
        <HeaderNavText color={primaryColor}>
          <h1>{module.title}</h1>
        </HeaderNavText>
        {next ? (
          <Keyboard onEnter={onClickNext}>
            <HeaderNavText onClick={onClickNext} tabIndex={2}>
              <p>{addDays(module.end_at, 7).toDateString()}</p>
              <ArrowRightIcon />
            </HeaderNavText>
          </Keyboard>
        ) : (
          <div />
        )}
      </StyledNav>
    </StyledHeader>
  );
};

export default ModuleHeader;
