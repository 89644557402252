/*******************************
 *     Common Development Config
 ******************************/

// Need to add scopes to the requests in order for it to work once scopes have been defined.
// Source: https://community.canvaslms.com/t5/Canvas-Developers-Group/Enforcing-scope-on-developer-key-breaks-OAuth-flow/td-p/142854
export const canvasConfig = {
  clientId: "194980000000000101",
  clientSecret:
    "0iglutw9OHU6d58J8nkwdR6n6LDdc78YASR2H8he9prbAVQ9LQVaD4RZWYsyXDaG",
  canvasURL: "https://blendedpartner.instructure.com",
  scopes: [
    "url:GET|/api/v1/accounts",
    "/auth/userinfo",
    "url:GET|/api/v1/courses",
    "url:POST|/api/v1/accounts/:account_id/courses",
    "url:GET|/api/v1/courses/:course_id/pages",
    "url:DELETE|/api/v1/courses/:course_id/pages/:url_or_id",
    "url:GET|/api/v1/courses/:course_id/assignments",
    "url:DELETE|/api/v1/courses/:course_id/assignments/:id",
    "url:GET|/api/v1/courses/:course_id/modules",
    "url:DELETE|/api/v1/courses/:course_id/modules/:id",
    "url:PUT|/api/v1/courses/:id",
    "url:POST|/api/v1/courses/:course_id/modules",
    "url:POST|/api/v1/courses/:course_id/assignments",
    "url:POST|/api/v1/courses/:course_id/modules/:module_id/items",
    "url:POST|/api/v1/courses/:course_id/pages",
    "url:POST|/api/v1/courses/:course_id/outcome_imports(/group/:learning_outcome_group_id)",
    "url:GET|/api/v1/courses/:course_id/outcome_imports/:id/created_group_ids",
    "url:POST|/api/v1/courses/:course_id/outcome_groups/:id/outcomes",
    "url:POST|/api/v1/courses/:course_id/rubrics",
    "url:GET|/api/v1/outcomes/:id",
    "url:GET|/api/v1/courses/:course_id/rubrics/:id",
    "url:POST|/api/v1/courses/:course_id/rubric_associations",
    "url:GET|/api/v1/courses/:course_id/rubrics",
    "url:GET|/api/v1/courses/:course_id/outcome_groups/:id",
    "url:GET|/api/v1/courses/:course_id/outcome_groups/:id/outcomes",
    "url:GET|/api/v1/courses/:course_id/outcome_imports/:id",
    "url:POST|/api/v1/courses/:course_id/rubric_associations/:rubric_association_id/rubric_assessments",
    "url:GET|/api/v1/courses/:course_id/assignments/:id",
    "url:DELETE|/api/v1/courses/:course_id/outcome_groups/:id",
    "url:DELETE|/api/v1/courses/:course_id/rubrics/:id",
    "url:GET|/api/v1/courses/:course_id/outcome_groups",
    "url:DELETE|/api/v1/courses/:id",
    "url:GET|/api/v1/users/:user_id/courses",
    "url:POST|/api/v1/courses/:course_id/files",
    "url:GET|/api/v1/progress/:id",
    "url:POST|/api/v1/progress/:id/cancel",
    "url:POST|/api/v1/courses/:course_id/folders",
    "url:GET|/api/v1/courses/:course_id/folders",
    "url:PUT|/api/v1/folders/:id",
    "url:PUT|/api/v1/courses/:course_id/assignments/:id",
    "url:GET|/api/v1/courses/:course_id/assignments/:id",
    "url:GET|/api/v1/courses/:course_id/pages/:url_or_id",
    "url:PUT|/api/v1/groups/:group_id/pages/:url_or_id",
  ],
};

export const stripeConfig = {
  publicKey:
    "pk_test_51IM2K1GzVqTnZKK03veKwyuoudBRZaJUntS2jWMLYgOGTOuRIBinJweQCD7i9MZloMgHN4slQnPl2M9p7PEJH7Rf00A5m07p1c",
};

export const stripeConfigPrivate = {
  ...stripeConfig,
  ...{
    secretKey:
      "sk_test_51IM2K1GzVqTnZKK0dmHeBMeQJ9OKQN4E9m7Q4vWhpp84EcpyVgOeiIkvGnwWqm6vNeftPlkOIo6bZ7x8KRn6u6A800D8azffRr",
  },
};

export const rrfConfig = {
  useFirestoreForProfile: true,
  userProfile: "users",
};

export const youtubeConfig = {
  key: "AIzaSyBWnPbdfKLeQu26krqKfeVWHskP-M8wn_M",
};

export const googleBooksConfig = {
  key: "AIzaSyD3o0U-msv0Zwr9_XUjtNmspbWbAeaA26g",
};
export const customSearchConfig = {
  key: "AIzaSyAPiatS7uVrcQx2F-MNZPGeBiPKUxK_B4E",
  cx: "adbb5a78b7ccb1dae",
};

export const firebaseConfig = {
  useSimulator: false,
};

export const appConfig = {
  syllabusVersion: 5,
};

export const webexConfig = {
  clientId: "C35e342a4bd1bb1c606d4e053b665739e4fb3b592aff90a1261fc30f3bbdb3c22",
};

export const webexConfigPrivate = {
  ...webexConfig,
  ...{
    clientSecret:
      "08ab0ce943b88f8950fc6542d10324b76a05dbcc738962251411a62297ac2bfd",
  },
};

export const blackboardConfig = {
  clientId: "150b0dea-d1e8-4bdd-8719-4144ba633ee1",
  blackboardUrl: "https://blackboard.blendedcourse.com",
};
