import {
  generateCanvasActivityHtml,
  generateCanvasHtml,
} from "./generateCanvasHtml";
import { htmlDecode } from "./htmlDecode";

import type {
  CourseDescription,
  CourseObjectives,
  CourseTitle,
  Instructors,
  Objective,
  OfficeHours,
  Schedule,
  Syllabus,
} from "../model/Syllabus";

export const SyllabusToThemeInterface = (syllabus: Syllabus) => {
  const title = (
    syllabus.sections.find(
      (section) => section.type === "CourseTitle"
    ) as CourseTitle
  )?.title;

  const number = (
    syllabus.sections.find(
      (section) => section.type === "CourseTitle"
    ) as CourseTitle
  )?.courseNumber;

  const instructors = (
    syllabus.sections.find(
      (section) => section.type === "Instructors"
    ) as Instructors
  )?.instructors.map((instructor) => ({
    instructor: instructor.name,
    mail: instructor.email,
  }));

  const officeHours = (
    syllabus.sections.find(
      (section) => section.type === "OfficeHours"
    ) as OfficeHours
  )?.officeHours;

  const courseDescription = (
    syllabus.sections.find(
      (section) => section.type === "CourseDescription"
    ) as CourseDescription
  )?.description;
  let description = "";

  if (courseDescription) {
    description = generateCanvasHtml(courseDescription);
  }

  const courseObjectives = (
    syllabus.sections.find(
      (section) => section.type === "CourseObjectives"
    ) as CourseObjectives
  )?.objectives;

  const scheduleSection = syllabus.sections.find(
    (section) => section.type === "Schedule"
  ) as Schedule;

  const weeks: Array<{ title: string }> = [];
  const Objectives: Array<{
    objectives: Objective[];
    assignmentPages:
      | Array<{ title: string; body: string; index: string }>
      | false;
  }> = [];
  const Activities: Array<{
    title: string;
    body: string;
    id: string;
    objectives: Objective[];
  }>[] = [];

  if (scheduleSection && scheduleSection.modules.length > 0) {
    scheduleSection.modules.forEach((module, key) => {
      Activities[key] = [];
      weeks.push({
        title: module.title,
      });
      const modeledObjectives = module.objectives.map((objective) => {
        return {
          id: objective.id,
          title: objective.title,
          description: objective.description,
        };
      });
      Objectives.push({
        objectives: modeledObjectives,
        assignmentPages:
          module.activities.length > 0 &&
          module.activities.map((activity) => {
            return {
              title: activity.title,
              index: activity.id,
              body: generateCanvasActivityHtml(activity)
                .replace(/<a.*<\/a>/gi, "")
                .replace(/<p>\s*<\/p>/gi, ""),
            };
          }),
      });
      module.activities.length > 0 &&
        module.activities.forEach((activity) => {
          Activities[key].push({
            title: activity.title,
            body: generateCanvasActivityHtml(activity).replaceAll(
              "<a",
              "<a class='external instructure_file_link inline_disabled icon-folder' style='min-height: 54px !important;background: #eff3ff;border-radius: 4px;width: fit-content;" +
                "display: flex;align-items: center; padding: 0 10px; color: #000000; text-decoration: none'"
            ),
            id: activity.id,
            objectives: activity.alignedObjectives ?? [],
          });
        });
    });
  }

  return {
    Course: {
      firebaseId: syllabus.firebaseId!,
      header: {
        title: title,
        number: number,
      },
      instructors: {
        instructors: instructors,
      },
      officeHours: {
        officeHours: officeHours,
      },
      description: {
        description: htmlDecode(description),
      },
      objectives: {
        objectives: courseObjectives,
      },
      schedule: {
        weeks: weeks,
      },
    },
    Activities: Activities,
    Objectives: Objectives,
  };
};
