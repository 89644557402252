import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

interface LoadingAnimationProps {
  text?: string;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Text = styled.span`
  justify-content: center;
  position: fixed;
  padding-top: 350px;
`;

const style = {
  height: 300,
  width: 300,
};

const LoadingAnimation: React.FC<LoadingAnimationProps> = (props) => {
  const phrases = [
    "We're getting things ready for you",
    "This could take a minute",
    "We're almost there!",
  ];
  const [index, setIndex] = useState(0);

  const currentPhrase = phrases[index];

  useEffect(() => {
    setTimeout(() => {
      setIndex(index === phrases.length - 1 ? 0 : index + 1);
    }, 3000);
  }, [index, phrases.length]);

  return (
    <Container>
      <Lottie animationData={loadingAnimation} loop autoplay style={style} />
      <Text>{currentPhrase}</Text>
    </Container>
  );
};

export default LoadingAnimation;
