import React from "react";

import type { ScheduleProps } from "../../../../../types";

const Schedule: React.FC<ScheduleProps> = (props) => {
  return (
    <div style={{ margin: "16px 0px" }}>
      <h1
        style={{
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "25px",
          color: "#030b1d",
        }}
      >
        Modules
      </h1>
      <div
        style={{
          marginTop: "14px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridGap: "10px",
            gridTemplateColumns: "auto auto auto auto",
          }}
        >
          {props.weeks.map((week, index) => {
            return (
              <div
                key={index}
                style={{
                  textAlign: "center",
                  border: "1.5px solid #193da9",
                  borderRadius: "4px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <a
                  href={`weekUrl-${index}`}
                  style={{
                    color: "#193da9",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "22px",
                    textDecoration: "none",
                    position: "absolute",
                    width: "100%",
                  }}
                >
                  {week.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
