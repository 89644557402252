import React from "react";

import type { InstructorsProps } from "../../../../../types";

const Instructors: React.FC<InstructorsProps> = (props) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      {props.instructors.length > 1 ? (
        <h1
          style={{
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "25px",
            color: "#030b1d",
          }}
        >
          Instructors
        </h1>
      ) : (
        <h1
          style={{
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "25px",
            color: "#030b1d",
          }}
        >
          Instructor
        </h1>
      )}
      {props.instructors.map((instructor) => {
        return (
          <ul
            style={{
              marginTop: "16px",
              fontWeight: "400",
              fontSize: "12pt",
              lineHeight: "19px",
              color: "#474e61",
            }}
          >
            <li>Instructor: {instructor.instructor}</li>
            <li>
              Email: <a href={`mailto:${instructor.mail}`}>{instructor.mail}</a>
            </li>
          </ul>
        );
      })}
    </div>
  );
};

export default Instructors;
