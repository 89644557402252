import React from "react";
import styled from "styled-components";
import clsx from "clsx";
import Edit from "../../../assets/icons/Edit";
import Reorder from "../../../assets/icons/Reorder";
import Create from "../../../assets/icons/Create";
import { Keyboard } from "grommet";

interface CreationNavButtonProps {
  text: string;
  week?: boolean;
  added: boolean;
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
}

const CustomNavButtonStyled = styled.div<CreationNavButtonProps>`
  border-radius: 4px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  color: #193da9;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }

  &:active {
    font-weight: 600;
  }

  &.added {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #193da9;
    box-sizing: border-box;

    &:hover {
      background: #eff3ff;
      border: 1px solid #193da9;
    }

    &:active {
      background: #dde6ff;
    }
  }

  &.week {
    margin: 8px 20px;

    span {
      padding: 0 10px !important;
    }
  }

  &.no-added {
    background: #ffffff;
    border: 1px solid #f1f6ff;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(7, 26, 67, 0.1);
    color: #030b1d;

    &:hover {
      box-shadow: 0 2px 6px rgba(7, 26, 67, 0.15);
    }
    &:active {
      border: 1px solid #f1f6ff;
      box-shadow: inset 0.5px 0px 3px rgba(7, 26, 67, 0.15);
    }
  }
  &.disabled {
    background: #ffffff;
    border: 1px solid #f1f6ff;
    color: #9da2ac;

    &:hover {
      font-weight: 400;
      cursor: not-allowed;
    }
  }
`;
const StyledCreateIcon = styled(Create)`
  padding: 0 6px;
  color: #0d3af8;
`;

const StyledEditIcon = styled(Edit)`
  padding: 0 6px 0 6px;
`;

const StyledReorderIcon = styled(Reorder)`
  padding: 0 4px 0 4px;
`;

const EditSectionDiv = styled.div`
  display: flex;
`;

const SectionName = styled.span`
  padding: 0 12px;
`;

const CreationNavButton: React.FC<CreationNavButtonProps> = (props) => {
  return (
    <Keyboard onEnter={() => !props.disabled && props.onClick?.()}>
      <CustomNavButtonStyled
        data-type="pill"
        tabIndex={2}
        added={props.added}
        onClick={() => !props.disabled && props.onClick?.()}
        text={props.text}
        className={clsx({
          added: props.added,
          "no-added": !props.added && !props.disabled,
          disabled: props.disabled,
          week: props.week,
        })}
      >
        {props.added || props.text === "Schedule" ? (
          <>
            <EditSectionDiv>
              {!props.week && <StyledReorderIcon />}
              <span
                className={clsx({
                  week: props.week,
                })}
              >
                {props.text}
              </span>
            </EditSectionDiv>
            <StyledEditIcon />
          </>
        ) : (
          <>
            <SectionName>{props.text}</SectionName>
            <StyledCreateIcon />
          </>
        )}
      </CustomNavButtonStyled>
    </Keyboard>
  );
};

export default CreationNavButton;
