import { OpenedResource } from "../../../model/Resource";

export enum SyllabusTypes {
  AddOpenedResource = "AddOpenedResource",
  ClearOpenedResources = "ClearOpenedResources",
  SetPlannerId = "SetPlannerId",
}

export interface AddOpenedResourceAction {
  type: typeof SyllabusTypes.AddOpenedResource;
  payload: {
    openedResources: OpenedResource[];
  };
}

export interface ClearOpenedResourcesAction {
  type: typeof SyllabusTypes.ClearOpenedResources;
  payload: undefined;
}

export interface SetPlannerIdAction {
  type: typeof SyllabusTypes.SetPlannerId;
  payload: {
    plannerId: string;
  };
}

export type SyllabusActions =
  | AddOpenedResourceAction
  | ClearOpenedResourcesAction
  | SetPlannerIdAction;
