import React from "react";
import { CourseObjectives } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import ObjectivesEditor from "./ObjectivesEditor";
import styled from "styled-components";
import { Box } from "grommet";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import { courseMapConfig } from "../../../config/config";
import { useAppSelector } from "../../../redux/configureStore";

const StyledSubLabel = styled.span`
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #474e61;
  margin: 8px 0;

  & .required {
    color: #e51144;
    margin-left: 5px;
  }
`;

const CourseObjectivesEditor: React.FC<SectionEditorProps> = ({ section }) => {
  const objectives = (section as CourseObjectives).objectives;
  const plannerId = useAppSelector((state) => state.syllabus.plannerId);

  return (
    <>
      <Box direction="row" gap="small">
        <StyledSubLabel>Course Objectives</StyledSubLabel>
        <RoundedButton
          type="primary"
          size="small"
          variant="blue"
          onClick={() =>
            window.location.replace(`${courseMapConfig.courseUrl}${plannerId}`)
          }
        >
          Edit Objectives in Planner
        </RoundedButton>
      </Box>
      {objectives.length > 0 &&
        objectives.map((objective) => {
          return <ObjectivesEditor objective={objective} />;
        })}
    </>
  );
};

export default CourseObjectivesEditor;
