import React, { useEffect, useState } from "react";
import { match } from "react-router-dom";
import {
  deleteComponentOnSyllabus,
  Syllabus,
  SyllabusComponent,
} from "../../model/Syllabus";
import { useGetSyllabus } from "../../hooks/useGetSyllabus";
import { Identifiable } from "utils/Identifiable";
import SyllabusEditor from "./SyllabusEditor";
import { AppState, useAppDispatch } from "../../redux/configureStore";
import { useSelector } from "react-redux";
import firebase from "firebase";
import { addSnackbarMessage } from "../../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import SyllabusRepository from "../../repositories/SyllabusRepository";
import PublicSyllabusRepository from "../../repositories/PublicSyllabusRepository";
import SyllabusTeacherRepository from "repositories/SyllabusTeacherRepository";
import { HidableComponent } from "../hoc/Hidable";
import styled from "styled-components";
import Exit from "../../assets/icons/Exit";
import { courseMapConfig } from "../../config/config";
import CustomModal from "../modals/CustomModal";
import { Box, Text } from "grommet";
import { setPlannerId } from "../../redux/syllabus/actions/SyllabusActions";

interface SyllabusIdEditorProps {
  match: match<Identifiable>;
}

const StyledExit = styled(Exit)`
  color: #f1f6ff;
`;

const ModalTitle = styled(Text)`
  font-family: Poppins;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  color: #030b1d;
`;

const ModalText = styled(Text)`
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  margin-top: 20px;
  line-height: 25px;
  color: #030b1d;
`;

const Alert = styled.div`
  position: fixed;
  display: flex;
  bottom: 25px;
  background-color: rgb(4, 170, 109);
  color: white;
  opacity: 1;
  transition: opacity 0.6s ease 0s;
  padding: 20px;
  right: 25%;
`;

const SyllabusIdEditor: React.FC<SyllabusIdEditorProps> = (props) => {
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const id = props.match.params.id;
  const { syllabus: dbSyllabus, isLoading } = useGetSyllabus(id);
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [syllabusSharedWithTeacher, setSyllabusSharedWithTeacher] =
    useState<boolean>(false);
  const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (syllabusSharedWithTeacher) {
      setTimeout(() => {
        setSyllabusSharedWithTeacher(false);
      }, 800);
    }
  });

  useEffect(() => {
    dispatch(setPlannerId(dbSyllabus?.courseMapId ?? ""));
    setSyllabus(dbSyllabus);
  }, [dbSyllabus, dispatch]);

  useEffect(() => {
    window.analytics.identify(auth.uid);
  }, [auth.uid]);

  useEffect(() => {
    if (
      auth.uid !== dbSyllabus?.createdBy &&
      dbSyllabus?.createdBy !== undefined
    ) {
      setShowRedirectModal(true);
      setTimeout(() => {
        setShowRedirectModal(false);
        window.location.replace(`${courseMapConfig.dashUrl}`);
      }, 30000);
    }
  }, [setShowRedirectModal, auth.uid, dbSyllabus]);

  const savePassword = async (hashedPassword: string) => {
    try {
      const repository = new SyllabusRepository();
      const oldSyllabus = await repository.findById(id);
      oldSyllabus.password = hashedPassword;
      oldSyllabus.shared = true;
      await repository.save(id, oldSyllabus);
    } catch (e) {
      const id = uuidv4();
      dispatch(
        addSnackbarMessage({
          id: id,
          message: "There was an error saving your syllabus.",
          type: "critical",
        })
      );
      const callableCreate = firebase.functions().httpsCallable("saveLog");
      callableCreate({
        message: e.message,
        payload: syllabus,
        id: id,
      });
      throw e;
    }
  };

  const deleteSection = async (componentToDelete: SyllabusComponent) => {
    const o = deleteComponentOnSyllabus(componentToDelete, syllabus!);

    setSyllabus(o);
    const repository = new SyllabusRepository();
    await repository.save(id, o);

    window.analytics.track("Section Deleted", {
      sectionType: componentToDelete!.type,
      syllabusId: syllabus!.firebaseId,
    });
  };

  if (syllabus) {
    return (
      <>
        <SyllabusEditor
          syllabus={syllabus}
          onReorderSyllabus={(syllabus: Syllabus) => {
            const repository = new SyllabusRepository();
            repository.save(id, syllabus);
          }}
          onSavingSyllabus={async (newSyllabus) => {
            const repository = new SyllabusRepository();
            await repository.save(newSyllabus.firebaseId!, newSyllabus);
          }}
          onChangePassword={(hashedPassword) => savePassword(hashedPassword)}
          onDeleteComponent={(component) => deleteSection(component)}
          onSyllabusIsShared={async (syllabus) => {
            const repository = new PublicSyllabusRepository();
            await repository.save(syllabus.firebaseId!, syllabus);

            window
              .open(
                `${window.location.protocol}//${window.location.host}/s/${syllabus.firebaseId}`,
                "_blank"
              )!
              .focus();
          }}
          onSyllabusIsSharedWithTeachers={async (syllabus) => {
            const repository = new SyllabusRepository();
            const oldSyllabus = await repository.findById(id);
            oldSyllabus.sharedWithTeacher = true;
            await repository.save(id, oldSyllabus);
            const syllabusTeacherPublicRepository =
              new SyllabusTeacherRepository();
            await syllabusTeacherPublicRepository.save(
              syllabus.firebaseId!,
              syllabus
            );
            setSyllabusSharedWithTeacher(true);
          }}
          onClickBackButton={() => {
            window.location.replace(
              `${courseMapConfig.dashUrl}${syllabus.courseMapId}`
            );
          }}
        />
        <HidableComponent isVisible={syllabusSharedWithTeacher}>
          <Alert>
            Your collaborator link has been created.
            <span onClick={() => setSyllabusSharedWithTeacher(false)}>
              <StyledExit />
            </span>
          </Alert>
        </HidableComponent>
        <HidableComponent isVisible={showRedirectModal}>
          <CustomModal size="mini" opened={showRedirectModal}>
            <Box align="center" justify="center" margin={{ top: "10%" }}>
              <ModalTitle>
                You don't have permission to see this syllabus.
              </ModalTitle>
              <ModalText>We will redirect you to your dashboard</ModalText>
            </Box>
          </CustomModal>
        </HidableComponent>
      </>
    );
  } else if (isLoading) {
    return <div />;
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default SyllabusIdEditor;
